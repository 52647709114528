import * as React from "react";

const SVGFlame = ({ fill, width, height }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M8.5 18C8.5 20.4148 10.2909 21 12.5 21C16.2587 21 17.5 18.5 15 13.5C11.5 18 11 11 11.5 9C10 12 8.5 14.8177 8.5 18Z" stroke="#F5A3A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 21C17.5495 21 20.5 18.0956 20.5 13.125C20.5 8.15444 12.5 3 12.5 3C12.5 3 4.5 8.15444 4.5 13.125C4.5 18.0956 7.45054 21 12.5 21Z" stroke="#F5A3A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SVGFlame;