
export const makeStyle = (theme: any) => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '258px',
      display: 'flex',
      margin: 'auto',
      flexDirection: 'column',
    },
  },
  errorBox: {
  },
  exitButtonContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    margin: '16px',
    backgroundColor: '#f3eded',
    borderRadius: '16px',
  },
})