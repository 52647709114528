import * as React from "react";

const SVGClover = ({ fill, width, height }: any) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 9.58301V13.7497" stroke="#272727" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 6.25828L10.0083 6.24902" stroke="#272727" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.0003 9.10686C7.64827 8.97422 2.95552 8.24276 3.00032 6.37811C3.04512 4.51346 4.73638 4.35049 5.57641 4.50209C5.42708 3.6304 5.64361 1.92114 7.70439 2.05758C9.76517 2.19401 10.0936 6.81395 10.0003 9.10686ZM10.0003 9.10686C12.3522 9.23951 17.0445 9.97068 16.9997 11.8353C16.9549 13.7 15.2636 13.8629 14.4236 13.7113C14.5729 14.583 14.3564 16.2923 12.2956 16.1559C10.2348 16.0194 9.90688 11.3998 10.0003 9.10686ZM10.0003 9.10686C10.1309 6.7192 10.8509 1.95484 12.6877 2.00032C14.5245 2.0458 14.685 3.76272 14.5357 4.6155C15.3944 4.4639 17.0781 4.68372 16.9437 6.77576C16.8093 8.8678 12.2589 9.20165 10.0003 9.10686ZM14.4162 13.7932C15.2375 14.1911 16.8801 15.5896 16.8801 18M9.98332 9.121C9.85266 11.5087 9.13213 16.2726 7.29535 16.2271C5.45856 16.1816 5.29803 14.4647 5.44736 13.612C4.5887 13.7635 2.90498 13.5437 3.03938 11.4517C3.17378 9.35965 7.72467 9.02621 9.98332 9.121Z" stroke={fill} strokeWidth="1.5"/>
    </svg>
  )
}
export default SVGClover;