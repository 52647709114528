
import { IBetType, IBetTypesByCategory } from "src/models/lottery/betType.models";
import { ApiState } from "../../models/core/api.common.model";
import { ACTIONS } from "../actions/game.action"
import { groupSessionsByDate, groupBetTypesByCategory } from "../services/game.service";
import { titleCase } from "src/common/utils/format.utils";
import { IDraw, IDrawListByDate } from "src/models/lottery/draw.model";
import { IGame } from "src/models/lottery/game.model";


interface IGameReducer {
  error: boolean;
  isFetching: boolean;
  errorMessage: string;
  stateBetTypes: ApiState;
  stateCurrentSessions: ApiState;
  // games
  game: IGame;
  gameId?: number;
  mainGridName: string;
  // sessions
  currentSessions: IDraw[];
  currentSesionsByDate: IDrawListByDate[];
  // betTypes
  betTypes: IBetType[];
  betTypesByCategory: IBetTypesByCategory[];
  betTypesActive: IBetType[];
}

const initialState = <IGameReducer>{
  error: false,
  isFetching: true,
  errorMessage: '',
  stateBetTypes: <ApiState>{ isError: false, pending: false },
  stateCurrentSessions: <ApiState>{ isError: false, pending: false },

  // games
  game: {} as IGame,
  gameId: undefined,
  mainGridName: '',

  // sessions
  currentSessions: [],
  currentSesionsByDate: [],

  // betTypes
  betTypes: [],
  betTypesByCategory: [],
  betTypesActive: [],
}


export default function gameReducer(state = initialState, action): IGameReducer {
  switch (action.type) {
    //------------------
    // GAME
    case ACTIONS.GAME_INFO: {
      return {
        ...state,
        game: action.payload,
        gameId: action.payload.gameId,
      }
    }
    case ACTIONS.GAME_ERROR: {
      return { ...state, error: true, isFetching: false, errorMessage: JSON.parse(JSON.stringify(action.payload)).message }
    }
    case ACTIONS.GAME_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.GAME_FETCHING_DONE: {
      return { ...state, isFetching: false, error: false }
    }
    //------------------
    // BET TYPES
    case ACTIONS.GAME_BETTYPES: {
      let betTypes = (action.payload ? [...action.payload] : []) as IBetType[];
      betTypes.forEach(E => E.name = titleCase(E.name));
      const betTypesActive = betTypes.filter(E => !E.status || E.status == 'ENABLED');
     const mainGridName = betTypesActive[0]?.listOfGrids[0].gridName;
      return {
        ...state,
        betTypes,
        betTypesActive,
        betTypesByCategory: groupBetTypesByCategory(betTypesActive),
        mainGridName
      }
    }
    case ACTIONS.BETTYPES_ERROR: {
      return {
        ...state,
        stateBetTypes: { pending: false, isError: true, errorMessage: JSON.parse(JSON.stringify(action.payload)).message },
      }
    }
    case ACTIONS.BETTYPES_FETCHING: {
      return {
        ...state,
        stateBetTypes: { pending: true, isError: false, errorMessage: '' },
      }
    }
    case ACTIONS.BETTYPES_FETCHING_DONE: {
      return {
        ...state,
        stateBetTypes: { pending: false, isError: false, errorMessage: '' },
      }
    }
    //------------------
    // SESSIONS
    case ACTIONS.GAME_CURRENT_SESSIONS: {
      return {
        ...state,
        currentSessions: action.payload,
        currentSesionsByDate: groupSessionsByDate(action.payload),
      }
    }
    case ACTIONS.CURRENT_SESSIONS_ERROR: {
      return {
        ...state,
        stateCurrentSessions: { pending: false, isError: true, errorMessage: JSON.parse(JSON.stringify(action.payload)).message },
      }
    }
    case ACTIONS.CURRENT_SESSIONS_FETCHING: {
      return {
        ...state,
        stateCurrentSessions: { pending: true, isError: false, errorMessage: '' },
      }
    }
    case ACTIONS.CURRENT_SESSIONS_FETCHING_DONE: {
      return {
        ...state,
        stateCurrentSessions: { pending: false, isError: false, errorMessage: '' },
      }
    }
    default:
      return state
  }
}