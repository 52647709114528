import { IBetType } from "./betType.models";
import { IDraw, TDrawsAsObject } from "./draw.model";

export enum EBasketStatus {
  valid = 0,
  empty,
  draw_closed,
  max_price_exceeded,
  not_enough_draws
}

export interface IBasket {
  gameId: number;
  gameType: string;
  isValid: boolean;
  status: EBasketStatus;
  itemList: IBasketItem[];
  totalStake: number;
}

export interface IBasketItem {
  betId: string;
  betTypeId: string;
  betTypeName: string;
  combinationCount: number;
  draw: IDraw; // draw object ref. (= the session)
  drawId: string; // undefined for for FAST instant games
  nbConsecutiveDraws: number; // for FAST instant games
  potentialAward: number;
  potentialAwardNet: number;
  price: number;
  selectionListByGrid: number[][]; //Ex: [[2, 4, 6, 8, 10], [4]],
  stake: number;
  selectedBetType: IBetType;
  status: EBasketStatus;
  taxAmount: number;
}