import * as service from "../services/player.service";

// Definition of the actions name

export const ACTIONS = {
  PLAYER_INFO_ERROR: 'PLAYER_INFO_ERROR',
  PLAYER_INFO_FETCHING: 'PLAYER_INFO_FETCHING',
  PLAYER_INFO: 'PLAYER_INFO',
  PLAYER_INFO_FETCHING_DONE: 'PLAYER_INFO_FETCHING_DONE',
}

// Dispatchers

// Lifecycle
export const set_state_error = (err: any) => {
  return { type: ACTIONS.PLAYER_INFO_ERROR, payload: err };
}

export const set_state_fetching = () => {
  return { type: ACTIONS.PLAYER_INFO_FETCHING };
}

export const set_player_infos = (playerData: any) => {
  return { type: ACTIONS.PLAYER_INFO, payload: playerData };
}

export const set_state_fetching_done = () => {
  return { type: ACTIONS.PLAYER_INFO_FETCHING_DONE };
}

// Actions

export const getPlayerInfo = (callback?: (success: boolean) => void) => {
  return async (dispatch: any) => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getPlayerInfo();
      if (r.data) {
        dispatch(set_player_infos(r.data));
        dispatch(set_state_fetching_done());
        callback && callback(true);
      }
    } catch (err) {
      callback && callback(false);
      dispatch(set_state_error(err));
    }
  }
}

export const startPlayerTokenAutoRefresh = () => {
  return async (dispatch: any) => {
    service.startPlayerTokenAutoRefresh(() => {
      dispatch(getPlayerInfo());
    });
  }
}
export const stopPlayerTokenAutoRefresh = () => {
  service.stopPlayerTokenAutoRefresh();
}