import { makeStyle } from './app.style';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import TopBar from './component/core/topBar/topBar.comp';
import { ID_MAIN_CONTENT } from './common/utils/dom.utils';

function App() {

  //====================================== Attributes
  const theme = useTheme();
  const style = makeStyle(theme);

  return (
    <Box sx={style.main}>
      <TopBar />
      <Box id={ID_MAIN_CONTENT} sx={style.mainContent}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default App;
