import Box from '@mui/material/Box';
import { Typography, useTheme, Popover, Button } from '@mui/material';
import { makeStyle } from './confirmPopup.style';
import { ReactNode } from 'react';

type Props = {
  onDismiss: () => void;
  onConfirm: () => void;
  title?: string;
  children?: ReactNode;
  open: boolean;
  dismissButtonText: string;
  confirmButtonText: string;
}

function ConfirmPopup({
  open,
  onDismiss,
  onConfirm,
  title,
  children,
  dismissButtonText,
  confirmButtonText
}: Props): JSX.Element {

  const style = makeStyle(useTheme());

  return (
    <Popover
      open={open}
      onClose={onDismiss}
      hideBackdrop
      disableScrollLock={true}
      anchorReference={"none"}
      sx={style.popover}
    >
      {title &&
        <Typography sx={style.title}>
          {title}
        </Typography>
      }

      {children}

      <Box sx={style.boxButton}>
        <Button
          variant="outlined"
          disableRipple
          disableElevation
          sx={[style.button, style.dismissButton]}
          onClick={onDismiss}
        >
          {dismissButtonText}
        </Button>
        <Button
          variant="contained"
          disableRipple
          disableElevation
          sx={style.button}
          onClick={onConfirm}
        >
          {confirmButtonText}
        </Button>
      </Box>
    </Popover>
  );
}

export default ConfirmPopup;
