export const makeStyle = (theme: any) => ({
  soldeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '24px',
    height: '96px',
    margin: '11px',
    background: theme.palette.sticker.main,
    [theme.breakpoints.up('lg')]: {
      marginLeft: '59px',
      marginRight: '59px'
    },
  },
  soldeSubContainer: {
    marginLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  soldeTitleText: {
    color: "#FFF",
    fontSize: '16px',
    fontWeight: 800
  },
  soldeAmountBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  totalBalance: {
    marginTop: '-10px',
    marginRight: "5px",
    color: '#FFF',
    fontSize: '32px',
    fontWeight: 800
  },
  currencyId: {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 800
  }
})