import { ACTIONS } from '../actions/basket.actions';

import { EBasketStatus, IBasket, IBasketItem } from 'src/models/lottery/basket.model';

// initial state of this reducer

const initialState = <IBasket>{
  gameId: 0,
  gameType: '',
  isValid: false,
  status: EBasketStatus.valid,
  itemList: <IBasketItem[]>[],
  totalStake: 0,
}

// Reducer to catch the actions

export default function basketReducer(state = initialState, action): IBasket {
  switch (action.type) {
    //------------------
    case ACTIONS.BASKET_CLEAR: {
      return { ...initialState }
    }
    case ACTIONS.BASKET_SET_ITEM_LIST: {
      return { ...state, itemList: action.payload }
    }
    case ACTIONS.BASKET_STATUS: {
      return {
         ...state, 
         status: action.payload,
        isValid: action.payload == EBasketStatus.valid,
       }
    }
    case ACTIONS.BASKET_TOTAL_STAKE: {
      return { ...state, totalStake: +action.payload }
    }
    case ACTIONS.BASKET_SET_GAME_INFO: {
      return {
        ...state,
        gameId: action.payload.gameId,
        gameType: action.payload.gameType,
      }
    }
    default:
      return state
  }
}