/**
 * Explain which numbers ares used in a grid.
 */
export interface IGridDefinition { 
  gridName: string;
  listOfLottoNumbers: Array<number>;
}

/**
 * It defines how can be used a grid. 
 * This definition is for one paytable.
 */
export interface IGridParameters { 
  gridName: string;
  requiredNumber: number;
  maxAdditional: number;
  additionalMultiplier?: Array<number>;
}

/**
 * One grid filled by the player
 */
export interface IGridSelection extends IGridParameters, IGridDefinition { 
  selectedNumbers: Array<number>;
}
/**
 * The grids filled by the player (as an object)
 */
export type TGridSelectionsObj = {[gridName: string]: IGridSelection}

/**
 * Type for UI
 */
export enum EGridPermutationMode { none, available, enabled }