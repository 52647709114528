import { Box, Button, Typography, useTheme, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyle } from "./sessionPicker.style";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { RootState } from "src/store/root.reducer";
import * as betBuilderActions from "src/store/actions/betBuilder.actions";
import dayjs from "dayjs";
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import SVGSession from "src/assets/icons/SVGSession";
import SVGSessionDesktop from "src/assets/icons/SVGSessionDesktop";
import SVGFlame from "src/assets/icons/SVGFlame";
import { IDraw } from "src/models/lottery/draw.model";
import { isExpiredSession } from "src/store/services/game.service";
import { useSessionExpirationChecker } from "./sessionPicker.hook";

dayjs.extend(isToday)
dayjs.extend(isTomorrow)

const SessionPicker = ({
  goNextStep
}) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>();
  const isLarge: boolean = useMediaQuery(theme.breakpoints.up('lg'));

  const sessionChecker = useSessionExpirationChecker();

  const currentGame = useSelector((state: RootState) => state.game.game);
  const currentSesions = useSelector((state: RootState) => state.game.currentSessions);
  const currentSesionsByDate = useSelector((state: RootState) => state.game.currentSesionsByDate);
  const selectedSessions = useSelector((state: RootState) => state.betBuilder.selectedSessions);

  const [allowMultiSession, setAllowMultiSession] = useState(false);
  const [validationBtnDisabled, setValidationBtnDisabled] = useState(true);

  //====================================== Hooks

  useEffect(() => {
    setAllowMultiSession(currentGame.gameType == 'instantLottery');
  }, [currentGame])

  useEffect(() => {
    if (currentSesions?.length == 1) {
      onSessionSelectChange(currentSesions[0], true)
    }
    sessionChecker.checkExpiredSessions();
  }, [currentSesions])

  useEffect(() => {
    sessionChecker.checkExpiredSessionsSelected();
    setValidationBtnDisabled(Object.keys(selectedSessions).length == 0);
  }, [selectedSessions]);

  //====================================== Functions

  const onSessionSelectChange = (session: IDraw, isOnly1session: boolean) => {
    if (isExpiredSession(session)) {
      sessionChecker.onExpiredSessionClick(session);
    }
    else if (allowMultiSession) {
      const up_selectedSessions = { ...selectedSessions };
      if (selectedSessions[session.drawId]) {
        delete up_selectedSessions[session.drawId];
      } else {
        up_selectedSessions[session.drawId] = session;
      }
      dispatch(betBuilderActions.set_sessions_selected(up_selectedSessions));
    }
    else {
      let single_session = {};
      single_session[session.drawId] = session;
      dispatch(betBuilderActions.set_sessions_selected(single_session));
      !isOnly1session && goNextStep();
    }
  }


  const onValidate = () => {
    const hasExpiredSessionsSelected = sessionChecker.checkExpiredSessionsSelected();
    if (Object.keys(selectedSessions).length > 0 && hasExpiredSessionsSelected == false) {
      goNextStep();
    }
  }

  const formatDateDivider = (date: string): string => {
    return dayjs(date).isToday() ? t('Session.today') :
      dayjs(date).isTomorrow() ? t('Session.tomorrow') :
        dayjs(date).format('LL');
  }

  //====================================== Render

  const renderSessionButton = (session: IDraw, isSelected: boolean, key: number) => {

    const textStyle = isSelected ? [style.sessionButtonText, style.sessionButtonTextSelected] : [style.sessionButtonText];
    const textStyleName = isSelected ? [style.sessionButtonTextName, style.sessionButtonTextSelected] : [style.sessionButtonTextName]

    return (
      <Grid item xs={12} lg={4} key={key}>
        <Button
          sx={[style.sessionButton, isSelected && style.sessionButtonSelected]}
          onClick={() => onSessionSelectChange(session, false)}
          disableRipple
        >
          <Typography sx={[...textStyle, style.sessionButtonText_Date]}>
            {dayjs(session.drawingDate).format('HH:mm')}
          </Typography>
          {session.drawingName &&
            <Typography sx={[...textStyleName, style.sessionButtonText_Name]}>
              {session.drawingName}
            </Typography>
          }
        </Button>
      </Grid>
    )
  }

  return (
    <Box id="session-picker" sx={style.container}>
      <Box sx={style.body}>

        <Box>
          <Box sx={style.topBoxContainer}>
            <Box sx={style.topBoxSubContainer}>
              <Typography sx={style.topBoxTitleText}>{t('Session.welcome')}</Typography>
              <Box sx={style.topBox}>
                <Typography sx={style.gameName}>{currentGame?.name}</Typography>
              </Box>
            </Box>
            {isLarge ? <SVGSessionDesktop /> : <SVGSession />}
          </Box>
          <Box sx={style.stepBox}>
            <SVGFlame />
            <Typography sx={style.stepTitle}>
              {t(allowMultiSession ? 'Session.select_game_sessions' : 'Session.select_game_session')}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={style.sessionContainer}
        >
          {currentSesionsByDate.map((sessionsList, key) => (
            <Box key={key}>
              <Typography sx={style.sessionDate}>
                {formatDateDivider(sessionsList.date)}
              </Typography>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {sessionsList.sessions.map((session, key) => (
                  renderSessionButton(session, !!selectedSessions[session.drawId], key)
                ))}
              </Grid>
            </Box>
          ))}
        </Box>
        {allowMultiSession &&
          <Box sx={style.validateContainer}>
            <Box sx={style.validateRow}>
              <Button
                onClick={onValidate}
                sx={[style.validateButton, validationBtnDisabled && style.validateButtonDisabled]}
                disableRipple
                disabled={validationBtnDisabled}
              >
                <Typography sx={validationBtnDisabled ? style.validateButtonTextDisabled : style.validateButtonText}>
                  {t('Session.validate_selection')}
                </Typography>
              </Button>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  )
}


//======================================
export default SessionPicker;