export interface IGame {
  name: string;
  gameId: number;
  description: string;
  gameType: Game.GameTypeEnum;
  external?: boolean;
  active?: boolean;
  activeForProvider?: boolean;
  category?: GameCategory;
}

export interface GameCategory {
  categoryId: string;
  categoryName: string;
  active: boolean;
  sealed: boolean;
}

export namespace Game {
  export type GameTypeEnum = 'instantLottery' | 'mutualLottery' | 'sportLottery' | 'sportBetting' | 'fastPlay';
  export const GameTypeEnum = {
      FastPlay: 'fastPlay' as GameTypeEnum,
      InstantLottery: 'instantLottery' as GameTypeEnum,
      MutualLottery: 'mutualLottery' as GameTypeEnum,
      SportLottery: 'sportLottery' as GameTypeEnum,
      SportBetting: 'sportBetting' as GameTypeEnum
  };
}
