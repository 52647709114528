import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyle } from "./betBuilderGridsSummary.style";
import { IGridSelection } from "src/models/lottery/grid.model";
import dayjs from "dayjs";
import { IDraw } from "src/models/lottery/draw.model";
import { zeroFill } from "src/common/utils/format.utils";
import { sortListByClosingDate } from "src/store/actions/game.action";

const BetBuilderGridsSummary = () => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);

  const scrollRef = useRef();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  //====================================== States

  const betBuilderData = useSelector((state: RootState) => state.betBuilder);
  const selectedBetType = useSelector((state: RootState) => state.betBuilder.selectedBetType);
  const gameName = useSelector((state: RootState) => state.game.game.name);
  const betTypesActive = useSelector((state: RootState) => state.game.betTypesActive);

  const [mainGrid, setMainGrid] = useState<IGridSelection>();
  const [mainSelectedNumbersCount, setMainSelectedNumbersCount] = useState(0);
  const [showScrollItemPosition, setShowScrollItemPosition] = useState(true);
  const [scrollItemPosition, setScrollItemPosition] = useState(1);

  //====================================== Effects

  useEffect(() => {
    if (betBuilderData) {
      const gridName = selectedBetType.listOfGridParameters[0]?.gridName;
      const _mainGrid = betBuilderData.selectedGrids[gridName];
      setMainGrid(_mainGrid);
      setMainSelectedNumbersCount(_mainGrid.selectedNumbers.length);
    }
  }, [betBuilderData])

  useEffect(() => {
    if (scrollRef.current) {
      const { scrollWidth, offsetWidth } = scrollRef.current;
      setShowScrollItemPosition(scrollWidth > offsetWidth);
    }
  }, [scrollRef.current])

  //====================================== Functions

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth } = scrollRef.current;
      const _scrollItemPosition = Math.ceil((scrollLeft + 1) / scrollWidth * (betBuilderData.selectedSessionCount + 1));
      if (scrollItemPosition != _scrollItemPosition)
        setScrollItemPosition(_scrollItemPosition);
    }
  }

  //====================================== Render

  const renderItem = (key: number, session: IDraw | null) => (
    <Box
      key={key}
      sx={[style.betBox, betBuilderData.selectedSessionCount <= 1 && style.betBoxLarge]}
    >
      <Typography sx={style.betTypeText}>
        {betTypesActive?.length == 1 ? gameName : selectedBetType?.name}
      </Typography>
      {session &&
        <Typography sx={style.dateText}>
          {session.drawingDate && dayjs(session.drawingDate).format('DD/MM/YY - HH:mm')}
        </Typography>
      }
      <Box sx={style.gridBox}>
        <Typography sx={style.gridtitle}>
          {`${mainSelectedNumbersCount} ${t(mainSelectedNumbersCount > 1 ? 'numbers' : 'number').toLowerCase()}`}
          {betBuilderData.isPermutationEnabled && `, ${betBuilderData.combinationCount} ${t('combinations').toLowerCase()}*`}
        </Typography>
        {betBuilderData.isPermutationEnabled &&
          <Typography sx={style.permutationText}>*{t('Grid.permutation_enabled')}</Typography>
        }
        <Box sx={style.numbersContainer}>
          {mainGrid?.selectedNumbers.map((numb, key) => (
            <Box key={key} sx={style.numbersBox}>
              <Typography sx={style.numberText}>
                {zeroFill(numb)}
                </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )

  return (
    <Box id="bet-builder-grids-summary" sx={style.container}>
      {showScrollItemPosition && !isLarge &&
        <Typography sx={style.scrollItemPosition}>
          {scrollItemPosition} / {betBuilderData.selectedSessionCount}
        </Typography>
      }

      <Box
        sx={style.sliderContainer}
        ref={scrollRef}
        onScroll={handleScroll}
      >
        <Box sx={style.sliderContent}>
          {
            sortListByClosingDate(Object.values(betBuilderData.selectedSessions)).map((session, key) =>
              renderItem(key, session)
            )}
          {
            betBuilderData.selectedSessionCount == 0 && renderItem(0, null)
          }
        </Box>
      </Box>
    </Box>
  )
}

export default BetBuilderGridsSummary;
