import { ENV } from '../../env';
import kp_axios, { PLATFORM_LOTTERY } from './api.koralplay.service';
import dayjs from 'dayjs';
import { IBetType, IBetTypesByCategory } from 'src/models/lottery/betType.models';

//=============================================================================
// API CALLS
//=============================================================================

// GAMES

export const createBetBooking = async (params) => {
  return kp_axios.post(`${ENV.API_URL}/betslipbooking/rest/v1/bookings/web-lottery`, params);
}

export const getStatusBetBooking = async (id) => {
  return kp_axios.get(`${ENV.API_URL}/betslipbooking/rest/v1/bookings/${id}/status`);
}