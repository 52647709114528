import { Theme } from "@mui/material";
import commonStyle from "src/common/style/commonStyle";

export const makeStyle = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '11px',
    paddingRight: '11px',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '60px',
      marginRight: '60px'
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '24px',
    height: '96px',
    background: theme.palette.sticker.main,
    marginBottom: '32px',
  },
  titleText: {
    color: "#FFF",
    fontSize: '24px',
    fontWeight: 800,
    marginLeft: '16px',
    marginTop: '30px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '10px',
    },
  },
  //RULES
  ruleGroupList: {
    height: "100%",
    [theme.breakpoints.up('lg')]: {
      overflowY: "auto",
    },
    ...commonStyle.slimScrollBar(theme),
  },
  //BET TYPE
  betTypeCollapse: {
    background: `linear-gradient(90deg, ${theme.palette.shades[600]} 0%, ${theme.palette.shades[500]} 100%)`,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    marginBottom: '16px',
    borderRadius: '16px',
    width: '100%',
    height: '48px',
    "&:hover, &:active": {
      background: `linear-gradient(90deg, ${theme.palette.shades[600]} 0%, ${theme.palette.shades[500]} 100%)`,
    },
  },
  betTypeCollapseOpen: {
    background: `linear-gradient(90deg, ${theme.palette.shades[900]} 0%, ${theme.palette.shades[600]} 100%)`,
    "&:hover, &:active": {
      background: `linear-gradient(90deg, ${theme.palette.shades[900]} 0%, ${theme.palette.shades[600]} 100%)`,
    },
  },
  betTypeCollapseText: {
    overflow: 'hidden',
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'none'
  },
  betTypeButtonBox: {
    height: '48px',
    display: 'flex',
    marginBottom: '10px'
  },
  betTypeButton: {
    backgroundColor: '#FFF',
    borderRadius: '16px',
    flex: 6,
    boxShadow: '0px 6px 12px 0px #00000014',
    "&:hover, &:active": {
      backgroundColor: '#FFF',
    },
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  betTypeButtonText: {
    color: '#333333',
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'none',
  },
  gameDescription: {
    padding: '16px',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    marginBottom: '8px',
    whiteSpace: 'pre-wrap',
    boxShadow: '0px 6px 12px 0px #00000014',
    "&:hover, &:active": {
      backgroundColor: '#FFF',
    },
  },
  groupArrowIcon: {
    width: '24px',
    height: '24px',
  },
  groupArrowIconOpen: {
    transform: 'rotate(180deg)',
  },
})