import { ENV } from 'src/env';
import { cache_create } from './cache.service';
import { IGameRuleSlide, IGameRuleSlideFilters } from 'src/models/lottery/gameRules.model';
import i18n from 'src/common/i18n/i18n';
import { IPayLine } from 'src/models/lottery/payLine.model';

//=============================================================================
// API CALLS
//=============================================================================

const GAMERULES_INDEX_URL = ENV.STORAGE_URL + '/game-lottery/public/rules/lottery_game_rules.json';

export const loadGameRules = (): any => {
  return fetch(GAMERULES_INDEX_URL).then(res => res.json());
}

//=============================================================================
// HELPERS
//=============================================================================

export const api_cache = cache_create('gameRules', 3600);

export const findSlides = (slides: IGameRuleSlide[], filters: IGameRuleSlideFilters): IGameRuleSlide[] => {
  return slides
    .filter(E =>
      E.lang == i18n.language
      && (filters.groupType == null || filters.groupType == E.groupType)
      && (filters.gameId == null || filters.gameId == E.gameId)
      && (filters.groupId == null || filters.groupId == E.groupId)
    )
    .sort((a, b) => a.slideOrder - b.slideOrder);
}

export const sortPaylines = (paylines: IPayLine[], gridName: string) => {
  const sortedPaylines = [...paylines];
  return sortedPaylines.sort((a, b) => {
    return b.winConditions[gridName]?.totalFoundNumbers - a.winConditions[gridName]?.totalFoundNumbers;
  });
}