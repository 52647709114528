import * as React from "react";

const SVGTicket = ({ fill, width, height }: any) => {
  return (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4L12 5.86945M12 14.4365L12 16M12 11.7822L12 8.05871" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.3616 16C16.8192 16 18 14.9073 18 13.5574V11.6135C17.7725 11.6153 17.5469 11.5749 17.3361 11.4947C17.1253 11.4145 16.9335 11.2961 16.7716 11.1462C16.6098 10.9963 16.4812 10.8179 16.3931 10.6213C16.305 10.4247 16.2592 10.2136 16.2584 10.0004C16.2584 9.10793 17.0352 8.38798 18 8.38798L17.9992 6.4426C17.9992 5.09268 16.8176 4 15.3608 4H4.64C3.1824 4.00075 2 5.09343 2 6.44335V8.45172C2.9648 8.45172 3.7416 9.10793 3.7416 10.0011C3.7416 10.8936 2.9648 11.6135 2 11.6135V13.5574C2 14.9073 3.1808 16 4.6384 16H15.3616Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  )
}
export default SVGTicket;
