import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

const SessionCountdownTimer = ({ sessions, style }) => {
  const [currentSession, setCurrentSession] = useState<any>(null);
  const [timeLeft, setTimeLeft] = useState(0);

  let timer: NodeJS.Timer;

  //====================================== Effects


  useEffect(() => {
    // Sort sessions by drawingDate
    setCurrentSession(sessions[0]);
  }, []);

  useEffect(() => {
    if (!currentSession) return;

    const targetTime = new Date(currentSession.closingDate || currentSession.drawingDate).getTime();

    updateTimeLeft(targetTime);
    timer = setInterval(() => updateTimeLeft(targetTime), 1000);

    return () => clearInterval(timer);
  }, [currentSession]);

  //====================================== Functions

  const updateTimeLeft = (targetTime: number) => {
    const now = new Date().getTime();
      const difference = targetTime - now;

      if (difference <= 0) {
        const currentIndex = sessions.findIndex(session => session.drawId === currentSession.drawId);
        if (currentIndex < sessions.length - 1) {
          setCurrentSession(sessions[currentIndex + 1]);
        } else {
          clearInterval(timer);
        }
      } else {
        setTimeLeft(difference);
      }
  }

  const formatTimeLeft = (milliseconds: number) => {
    if (milliseconds <= 0) {
      return '';
    }
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  //====================================== Render

  return (
    <Typography sx={style}>
      {formatTimeLeft(timeLeft)}
    </Typography>
  );
};

export default SessionCountdownTimer;