import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  container: {    
    marginTop: '32px',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '139px',
      paddingRight: '139px',
    }
  },
  slide: {
    width: '100%',
  },
  slideTitle: {
    color: '#333',
    fontSize: '18px',
    fontWeight: 600,
    paddingTop: '24px',
  },
  slideText: {
    fontSize: '16px',
    textAlign: 'left',
    paddingTop: '8px',
    fontWeight: 400,
    color: "#878787",
  },
  slideImageContainer: {
    textAlign: 'center',
    borderRadius: '8px',
    border: '1px solid #EAECF0'
  },
  slideDivider: {
    marginTop: '24px',
    marginBottom: '24px',
  },
})