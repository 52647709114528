const SVGTrash = ({ fill, width, height }: any) => {
  return (
    <svg width={width || 20} height={height || 20} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill || "#1D1D1D"} d="M20 9L18.005 20.3463C17.8369 21.3026 17.0062 22 16.0353 22H7.96474C6.99379 22 6.1631 21.3026 5.99496 20.3463L4 9" />
      <path stroke={fill || "#1D1D1D"} d="M20 9L18.005 20.3463C17.8369 21.3026 17.0062 22 16.0353 22H7.96474C6.99379 22 6.1631 21.3026 5.99496 20.3463L4 9H20Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path stroke={fill || "#1D1D1D"} d="M21 6H15.375M15.375 6V4C15.375 2.89543 14.4796 2 13.375 2H10.625C9.52043 2 8.625 2.89543 8.625 4V6M15.375 6H8.625M3 6H8.625" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export default SVGTrash;
