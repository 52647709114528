import { Box, Typography, useTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/root.reducer";
import SVGBalance from "../../../assets/icons/SVGBalance";
import { useTranslation } from "react-i18next";
import { makeStyle } from "./myWallet.style";
import { useEffect } from "react";
import * as PlayerActions from 'src/store/actions/player.actions';

const MyWalletPage = () => {

  const {t} = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>()

  useEffect(() => {
    dispatch(PlayerActions.getPlayerInfo());
  }, [])

  const playerInfo = useSelector((state: RootState) => state.player.playerInfo);

  return (
    <Box>
      <Box sx={style.soldeContainer}>
        <Box sx={style.soldeSubContainer}>
          <Typography sx={style.soldeTitleText}>{t('balance')}</Typography>
          <Box sx={style.soldeAmountBox}>
            <Typography sx={style.totalBalance}>{playerInfo.totalBalance}</Typography>
            <Typography sx={style.currencyId}>{playerInfo.currencyId}</Typography>
          </Box>
        </Box>
        <SVGBalance />
      </Box>
    </Box>
  )
}

export default MyWalletPage;