import { configureStore } from '@reduxjs/toolkit';
import app from './reducers/app.reducer';
import basket from './reducers/basket.reducer';
import betBuilder from './reducers/betBuilder.reducer';
import game from './reducers/game.reducer';
import gameRules from './reducers/gameRules.reducer';
import player from './reducers/player.reducer';
import taxes from './reducers/taxes.reducer';
import booking from './reducers/booking.reducer';
import tickets from './reducers/ticket.reducer';

export const store = configureStore({
  reducer: {
    app,
    basket,
    betBuilder,
    booking,
    game,
    gameRules,
    player,
    taxes,
    tickets
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type RootStateFn = () => RootState;

export default store;
