const SVGSessionDesktop = ({ fill, width, height }: any) => {
  return (
    <svg width="251" height="96" viewBox="0 0 251 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M91.2759 2.17283L108.274 16.0965L105.186 19.1292L89.0658 8.75924L91.2759 2.17283Z" fill="#FBFFFF"/>
    <mask id="mask0_1705_7495" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="251" height="112">
    <rect width="251" height="112" fill="url(#paint0_linear_1705_7495)"/>
    </mask>
    <g mask="url(#mask0_1705_7495)">
    <path d="M85.2109 114.734C83.2002 111.954 78.0895 106.203 75.7855 103.676L71.7012 120.737L85.5251 121.053C86.2582 120.105 87.2217 117.514 85.2109 114.734Z" fill="url(#paint1_linear_1705_7495)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M221.664 68.0188L221.215 99.4873C220.946 118.312 205.467 133.352 186.643 133.08L180.452 132.991C179.86 132.844 179.321 132.686 178.843 132.513C150.674 123.366 125.291 102.144 116.122 92.6764L182.328 27.7031C191.865 36.8017 213.065 57.5808 221.575 67.9088C221.605 67.9452 221.635 67.9818 221.664 68.0188Z" fill="url(#paint2_linear_1705_7495)"/>
    <ellipse cx="148.583" cy="59.8452" rx="46.5828" ry="46.8452" fill="url(#paint3_radial_1705_7495)"/>
    <path d="M148.857 72.2338C145.93 72.8509 143.21 72.8515 140.697 72.2358C138.198 71.6172 136.086 70.484 134.359 68.8363C132.632 67.1886 131.491 65.1237 130.935 62.6418L139.634 60.8081C140.067 62.4642 141.03 63.6782 142.524 64.45C144.017 65.2218 145.648 65.4215 147.415 65.0489C148.823 64.752 150.003 64.1731 150.952 63.3121C151.913 62.4341 152.583 61.3605 152.961 60.0913C153.351 58.8051 153.396 57.4154 153.096 55.9221C152.79 54.4005 152.204 53.1291 151.337 52.1078C150.484 51.0836 149.44 50.3638 148.205 49.9486C146.971 49.5334 145.641 49.4687 144.216 49.7544C142.973 50.0164 141.817 50.5317 140.748 51.3003C139.693 52.0661 138.931 52.9682 138.463 54.0066L130.196 54.2293L127.625 30.9833L153.556 25.5172L155.058 32.9979L136.52 36.9054L137.599 47.9775L137.847 47.9251C138.378 46.6972 139.325 45.5725 140.689 44.5508C142.052 43.5292 143.666 42.8219 145.53 42.4289C148.085 41.8905 150.486 42.0231 152.734 42.8267C154.982 43.6303 156.901 44.9806 158.491 46.8774C160.078 48.7602 161.143 51.0908 161.686 53.869C162.285 56.7823 162.143 59.5142 161.259 62.0647C160.385 64.5982 158.885 66.7648 156.756 68.5644C154.638 70.3471 152.005 71.5702 148.857 72.2338Z" fill="url(#paint4_linear_1705_7495)"/>
    <ellipse cx="34.1584" cy="70.3452" rx="33.1584" ry="33.3452" fill="url(#paint5_radial_1705_7495)"/>
    <ellipse cx="214.912" cy="92.092" rx="31.9122" ry="32.092" fill="url(#paint6_radial_1705_7495)"/>
    <path d="M198.384 92.8383L199.787 89.4559L210.682 85.4361C211.621 85.0661 212.424 84.7159 213.093 84.3855C213.769 84.0582 214.329 83.6949 214.773 83.2958C215.22 82.8894 215.565 82.3935 215.808 81.8081C216.078 81.1576 216.163 80.5357 216.063 79.9423C215.966 79.3416 215.715 78.8078 215.31 78.3407C214.908 77.8664 214.383 77.4935 213.736 77.2221C213.061 76.9386 212.414 76.8287 211.797 76.8922C211.18 76.9557 210.629 77.1868 210.143 77.5853C209.657 77.9838 209.267 78.5372 208.973 79.2455L204.542 77.3863C205.145 75.9336 205.995 74.8097 207.093 74.0145C208.191 73.2193 209.451 72.7763 210.872 72.6855C212.294 72.5946 213.774 72.8719 215.312 73.5173C216.893 74.1807 218.152 75.0401 219.089 76.0954C220.037 77.1465 220.633 78.3043 220.876 79.5689C221.119 80.8334 220.975 82.1053 220.444 83.3846C220.096 84.2229 219.588 84.9811 218.918 85.659C218.256 86.34 217.301 87.0042 216.054 87.6518C214.809 88.2921 213.141 88.9879 211.049 89.7392L206.507 91.563L206.444 91.7148L215.715 95.6051L214.123 99.4428L198.384 92.8383Z" fill="url(#paint7_linear_1705_7495)"/>
    <path d="M84.3806 75.6175L83.3939 77.705L60.2326 78.1055L67.5987 69.7691L84.3806 75.6175Z" fill="#FDD470"/>
    <path d="M126.073 106.69L127.705 108.386L118.19 126.261L113.139 116.872L126.073 106.69Z" fill="#FDD470"/>
    <path d="M217.608 18.9503L201.915 32.0021L202.845 33.2148L219.131 26.358L217.608 18.9503Z" fill="white"/>
    <path d="M233.586 60.7276L249.772 47.9996L232.34 52.9699L233.586 60.7276Z" fill="#FDD470"/>
    <ellipse cx="67.704" cy="110.764" rx="10.704" ry="10.7643" fill="url(#paint8_radial_1705_7495)"/>
    <ellipse cx="90.0561" cy="118.679" rx="7.8706" ry="7.91494" fill="url(#paint9_radial_1705_7495)"/>
    </g>
    <path d="M20.9504 51.5585C22.0733 50.9593 23.2441 50.5707 24.4629 50.3926C25.6781 50.2078 26.8989 50.2991 28.1254 50.6665C29.3483 51.0272 30.5345 51.7295 31.684 52.7734C32.8335 53.8172 33.904 55.2682 34.8955 57.1263C35.8152 58.8337 36.4332 60.4746 36.7495 62.049C37.0622 63.6167 37.0909 65.0783 36.8354 66.434C36.5866 67.7861 36.0645 68.9963 35.2693 70.0647C34.4705 71.1264 33.4161 72.0067 32.1061 72.7058C30.6624 73.4761 29.2412 73.8782 27.8423 73.9119C26.4466 73.9354 25.1647 73.6491 23.9966 73.0531C22.8351 72.4535 21.877 71.5995 21.1223 70.4909L26.0048 67.8856C26.5223 68.4853 27.1211 68.8269 27.8011 68.9106C28.4776 68.9875 29.1601 68.8423 29.8485 68.475C31.105 67.8045 31.7534 66.7586 31.7937 65.3375C31.8339 63.9164 31.3547 62.2701 30.3561 60.3987L30.2358 60.4629C30.2765 61.2311 30.1506 61.9767 29.8579 62.6996C29.572 63.4189 29.1472 64.0792 28.5836 64.6805C28.0201 65.2817 27.3573 65.7857 26.5953 66.1923C25.3655 66.8485 24.1296 67.1473 22.8876 67.0888C21.6523 67.0267 20.5052 66.647 19.4462 65.9498C18.3939 65.2491 17.5274 64.2689 16.8466 63.0092C16.0839 61.5958 15.7478 60.1694 15.8384 58.73C15.9254 57.2839 16.4048 55.9376 17.2767 54.6909C18.1449 53.4376 19.3695 52.3935 20.9504 51.5585ZM23.0134 55.3521C22.3985 55.6802 21.9271 56.1164 21.5993 56.6606C21.2781 57.2012 21.1107 57.7886 21.097 58.4227C21.0833 59.0569 21.2421 59.6763 21.5733 60.2809C21.9046 60.8856 22.334 61.3605 22.8617 61.7058C23.3961 62.0475 23.9739 62.2372 24.5952 62.2749C25.2231 62.3091 25.8411 62.1639 26.4493 61.8393C26.9038 61.5968 27.2803 61.2886 27.5788 60.9146C27.8773 60.5407 28.0918 60.13 28.2223 59.6825C28.3492 59.2284 28.3876 58.7614 28.3376 58.2814C28.2943 57.7979 28.1498 57.3341 27.9041 56.8898C27.58 56.2985 27.1508 55.8321 26.6164 55.4904C26.082 55.1487 25.4975 54.9625 24.8629 54.9319C24.2315 54.8911 23.615 55.0311 23.0134 55.3521Z" fill="url(#paint10_linear_1705_7495)"/>
    <defs>
    <linearGradient id="paint0_linear_1705_7495" x1="-2.81315e-06" y1="35" x2="254.809" y2="69.9118" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7D7CF9"/>
    <stop offset="1" stopColor="#C621E5"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1705_7495" x1="71.7012" y1="110.943" x2="81.4989" y2="121.314" gradientUnits="userSpaceOnUse">
    <stop stopOpacity="0.6"/>
    <stop offset="1" stopOpacity="0.15"/>
    </linearGradient>
    <linearGradient id="paint2_linear_1705_7495" x1="137" y1="49.8553" x2="221.867" y2="127.204" gradientUnits="userSpaceOnUse">
    <stop offset="0.288863" stopOpacity="0.6"/>
    <stop offset="0.782491" stopOpacity="0.15"/>
    </linearGradient>
    <radialGradient id="paint3_radial_1705_7495" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.859 45.4057) rotate(37.1436) scale(70.8624 70.9713)">
    <stop offset="0.545283" stopColor="white"/>
    <stop offset="1" stopColor="#BCA8F0"/>
    </radialGradient>
    <linearGradient id="paint4_linear_1705_7495" x1="134.433" y1="28.7344" x2="153.688" y2="73.288" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7951E0"/>
    <stop offset="1" stopColor="#5827D8"/>
    </linearGradient>
    <radialGradient id="paint5_radial_1705_7495" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.4071 60.0669) rotate(37.1436) scale(50.4411 50.5186)">
    <stop offset="0.545283" stopColor="white"/>
    <stop offset="1" stopColor="#BCA8F0"/>
    </radialGradient>
    <radialGradient id="paint6_radial_1705_7495" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(200.715 82.2) rotate(37.1436) scale(48.5454 48.62)">
    <stop offset="0.545283" stopColor="white"/>
    <stop offset="1" stopColor="#BCA8F0"/>
    </radialGradient>
    <linearGradient id="paint7_linear_1705_7495" x1="209.617" y1="73.5329" x2="211.938" y2="97.9607" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7951E0"/>
    <stop offset="1" stopColor="#5827D8"/>
    </linearGradient>
    <radialGradient id="paint8_radial_1705_7495" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(62.9421 107.446) rotate(37.1436) scale(16.2831 16.3081)">
    <stop offset="0.545283" stopColor="#FDD470"/>
    <stop offset="1" stopColor="#D39703"/>
    </radialGradient>
    <radialGradient id="paint9_radial_1705_7495" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(86.5547 116.239) rotate(37.1436) scale(11.9729 11.9913)">
    <stop offset="0.545283" stopColor="#FDD470"/>
    <stop offset="1" stopColor="#D39703"/>
    </radialGradient>
    <linearGradient id="paint10_linear_1705_7495" x1="15" y1="52.5" x2="34.0428" y2="70.9714" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7951E0"/>
    <stop offset="1" stopColor="#5827D8"/>
    </linearGradient>
    </defs>
    </svg>
  )
}
export default SVGSessionDesktop;
