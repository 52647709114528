import { ENV } from 'src/env';
import kp_axios, { PLATFORM_LOTTERY } from './api.koralplay.service';
import { cache_create } from './cache.service';
import { ITaxLevel } from 'src/models/ticketing/taxes.model';

//=============================================================================
// API CALLS
//=============================================================================

const ENGINE_URL = ENV.API_URL + ENV.ENGINE_PATH;

export const loadTaxes = () => {
  let params = {
    platform: PLATFORM_LOTTERY,
  };
  return kp_axios.get(`${ENGINE_URL}/v2-a/webplayer/games/tax/description`, { params });
}

//=============================================================================
// HELPERS
//=============================================================================

export const tax_api_cache = cache_create('tax', 3600);

export const computeNetAmount = (grossAmount: number, taxList : ITaxLevel[]): number => {
		//Note: if no tax found: netAmount = grossAmount
		//Note: the maxGain is optional
		//Note: in case of invalid amount (decimals), round to the nearest valid integer value. Ex: 100.5 => 101, 100.4 => 100.
		//Note: if a threshold exists in the taxLevel, we substruct the threshold value first from the grossAmount, and we apply tax on the difference
		try {
			let taxLevel = taxList.find(E => E.minGain <= grossAmount && (!E.maxGain || grossAmount <= E.maxGain));

			if(!taxLevel){
				return grossAmount;
			}

			let taxThreshold = taxLevel.taxThreshold || 0;
			let taxRate = grossAmount > taxThreshold  ? taxLevel.taxValue : 0;
			let taxedGrossAmount = grossAmount - taxThreshold;
			return Math.round(taxThreshold + ( taxedGrossAmount * (1 - taxRate)));
		}
		catch (err) {
			return grossAmount;
		}
}