import { useEffect, useState } from "react";
import { Box, useTheme, Typography, Button, Tooltip, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import { IBetTicket, EBetSettlementStatus, EBetPaymentStatus } from "src/models/ticketing/ticket.model";
import { makeStyle } from "./betItem.style";
import { useTranslation } from "react-i18next";
import SVGChevronDown from "src/assets/icons/SVGChevronDown";
import dayjs from "dayjs";
import { IBetType } from "src/models/lottery/betType.models";
import { zeroFill } from "src/common/utils/format.utils";
import { computeCombinations } from "src/common/utils/math.utils";
import { Game } from "src/models/lottery/game.model";
import SupportPopup from "src/component/core/supportPopup/supportPopup.comp";
import { computeNetAmount } from "src/store/services/taxes.service";
import { IDraw, IGridDrawn } from "src/models/lottery/draw.model";
import SVGInfo from "src/assets/icons/SVGInfo";
import TaxesPopup from "src/component/core/taxesPopup/taxesPopup.comp";

type Props = {
  item: IBetTicket,
  index: number
}

const BetItem = ({ item, index }: Props): JSX.Element => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const { betDetails } = item;

  //====================================== States

  const [showDetails, setShowDetails] = useState(false);
  const [betTypeData, setBetTypeData] = useState<IBetType>();
  const [maxNetAmount, setMaxNetAmount] = useState<number>(0);
  const [combinationCount, setCombinationCount] = useState<number>(0);
  const [errorTextKey, setErrorTextKey] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openSupportPopup, setOpenSupportPopup] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);
  const [listOfGridDrawn, setListOfGridDrawn] = useState<IGridDrawn[]>([]);
  const [isDrawPast, setIsDrawPast] = useState(false);
  const [showTaxesPopup, setShowTaxesPopup] = useState<boolean>(false);


  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  //
  const betTypesActive = useSelector((state: RootState) => state.game.betTypesActive);
  const gameName = useSelector((state: RootState) => state.game.game.name);
  const betTypes = useSelector((state: RootState) => state.game.betTypes);
  //
  const currency: string = useSelector((state: RootState) => state.player.playerInfo.currencyId);
  //
  const useTaxes: boolean = useSelector((state: RootState) => state.taxes.useTaxes);
  const taxLevels = useSelector((state: RootState) => state.taxes.taxLevels);
  //
  const drawResultList: IDraw[] = useSelector((state: RootState) => state.tickets.drawResultList);
  const isDrawResultsFetching = useSelector((state: RootState) => state.tickets.isDrawResultsFetching);

  //====================================== Effects

  useEffect(() => {
    if (item.paymentStatus === EBetPaymentStatus.PAYMENT_BLOCKED && item.settlementStatus === EBetSettlementStatus.CANCELED_TICKET) setErrorTextKey('Ticket.canceled_payment_blocked_error');
    else if (item.paymentStatus === EBetPaymentStatus.PAYMENT_BLOCKED && item.settlementStatus === EBetSettlementStatus.WIN) setErrorTextKey('Ticket.win_payment_blocked_error');
    else setErrorTextKey('')
  }, [item])

  useEffect(() => {
    item && setIsDrawPast(item.settlementStatus != EBetSettlementStatus.WAIT);
  }, [item])

  useEffect(() => {
    // Get the betType associated to the bet
    const _betTypeData = betTypes?.find(betType => +betType.betTypeId == betDetails.betTypeId);
    setBetTypeData(_betTypeData)
  }, [betTypes]);

  useEffect(() => {
    // compute combinations
    if (betTypes && betTypeData) {
      setCombinationCount(
        computeCombinations(
          betDetails.listOfBetGrids[0].listOfLottoNumbers.length,
          betTypeData?.listOfGridParameters[0]?.requiredNumber
        )
      )
    }
  }, [betTypeData])

  useEffect(() => {
    // Compute net amount
    let potentialAward = 0;
    if (betTypeData && item.betDetails.gameType == Game.GameTypeEnum.InstantLottery) {
      const maxStakeMultiplier = Math.max(...betTypeData.listOfPayLines.map(E => E.gainValue));
      potentialAward = item.price * maxStakeMultiplier;
      setMaxNetAmount(potentialAward)
    }
  }, [betTypeData])

  useEffect(() => {
    setTaxAmount((maxNetAmount - computeNetAmount(maxNetAmount, taxLevels)))
  }, [maxNetAmount, taxLevels])

  useEffect(() => {
    //Note: 1 online bet = 1 draw
    const _itemDrawResult = drawResultList.find(D => D.drawId == item.betDetails.listOfStatusAndDraws[0].draw?.drawId);
    setListOfGridDrawn(_itemDrawResult?.listOfGridDrawn || []);
    item.settlementStatus == EBetSettlementStatus.WAIT
  }, [drawResultList])

  //====================================== Functions

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  }
  
  const openTaxesPopup = () => {
    setShowTaxesPopup(true)
  }

  const computeNumberBoxStyle = (numb: number, winNumbs: number[], item: IBetTicket) => {
    switch (item.settlementStatus) {
      case EBetSettlementStatus.WIN:
        return winNumbs?.includes(numb) && style.selectionNumberBoxWin;
      case EBetSettlementStatus.LOST:
        return style.selectionNumberBoxLost;
      default:
        return {};
    }
  }

  const computeItemBorderStyle = (item: IBetTicket) => {
    if (item.paymentStatus === EBetPaymentStatus.PAYMENT_BLOCKED) {
      return style.containerError;
    }
    switch (item.settlementStatus) {
      case EBetSettlementStatus.WIN: return style.containerSuccess;
      case EBetSettlementStatus.CANCELED_GAME:
      case EBetSettlementStatus.CANCELED_TICKET: return style.containerError;
      default: return {};
    }
  }

  const computeStatusBoxStyle = (item: IBetTicket) => {
    switch (item.settlementStatus) {
      case EBetSettlementStatus.WIN:
        if (item.paymentStatus == EBetPaymentStatus.PAYMENT_BLOCKED) {
          return style.statusContainerLost
        } else {
          return style.statusContainerWon;
        }
      case EBetSettlementStatus.LOST:
        return style.statusContainerLost;
      case EBetSettlementStatus.CANCELED_GAME:
      case EBetSettlementStatus.CANCELED_TICKET:
        return style.statusContainerCanceled;
      default:
        return {}
    }
  }

  //====================================== Render

  const renderBetStatus = () => {
    if (item.paymentStatus === EBetPaymentStatus.PAYMENT_BLOCKED)
      return (
        <Typography sx={[style.statusText, style.statusTextWon]}>
          {t(`Ticket.won`)}: {betDetails.amtTotalWon.toLocaleString()} {currency}
        </Typography>
      )
    switch (item.settlementStatus) {
      case EBetSettlementStatus.WIN: return (
        <Typography sx={[style.statusText, style.statusTextWon]}>
          {t(`Ticket.won`)}: {betDetails.amtTotalWon.toLocaleString()} {currency}
        </Typography>
      );
      case EBetSettlementStatus.LOST: return (
        <Typography sx={[style.statusText, style.statusTextLost]}>
          {t(`Ticket.lost`)}
        </Typography>
      );
      case EBetSettlementStatus.CANCELED_GAME:
      case EBetSettlementStatus.CANCELED_TICKET: return (
        <Typography sx={[style.statusText, style.statusTextLost]}>
          {t(`Ticket.canceled`)}
        </Typography>
      );
      default: return (
        <Typography sx={style.statusText}>
          {t(`Ticket.wait`)}
        </Typography>
      );
    }
  }

  return (
    <Box key={index} sx={[style.container, computeItemBorderStyle(item)]}>

      <Box sx={[style.statusContainer, computeStatusBoxStyle(item)]}>
        {renderBetStatus()}
      </Box>

      {/* HEADER */}
      <Box sx={style.header}>
        <Box sx={style.headerLeft}>
          <Typography sx={style.title}>
            {betTypesActive?.length == 1 ? gameName : betTypeData?.name}
          </Typography>
          <Typography sx={style.date}>
            {dayjs(betDetails.listOfStatusAndDraws[0].draw.drawingDate).format('DD/MM/YY - HH:mm')}
          </Typography>
        </Box>
      </Box>

      {/* SELECTION */}
      <Box sx={style.selection}>

        <Box sx={style.selectionTitle}>
          <Typography sx={style.selectionTitleText}>
            {betDetails.listOfBetGrids[0].listOfLottoNumbers.length - betDetails.listOfBetGrids[0].usedAdditionalNumbers} {t(betDetails.listOfBetGrids[0].listOfLottoNumbers.length > 1 ? 'numbers' : 'number').toLowerCase()}
            {betDetails.listOfBetGrids[0].usedAdditionalNumbers >= 1 &&
              <Typography sx={style.selectionTitleText} component="label">
                ,&nbsp;
                {combinationCount} {t('combinations').toLowerCase()}*
              </Typography>
            }
          </Typography>
          {betDetails.listOfBetGrids[0].usedAdditionalNumbers >= 1 &&
            <Typography sx={style.selectionTitleSubText}>
              *{t('Bet.active_permutation')}
            </Typography>
          }
        </Box>



        <Box sx={style.selectionNumberList}>
          {betDetails.listOfBetGrids[0].listOfLottoNumbers.map((numb, key) => (
            <Box key={key + '.' + key} sx={[style.selectionNumberBox, computeNumberBoxStyle(numb, listOfGridDrawn[0]?.winningNumbers, item)]}>
              <Typography sx={style.selectionNumberText}>
                {zeroFill(numb)}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Draw results */}
        {isDrawPast &&
          <>
            <Box sx={[style.selectionTitle, { marginTop: '16px' }]}>
              <Typography sx={style.selectionTitleText}>
                {t('Ticket.draw_results')}
              </Typography>
            </Box>
            <Box sx={style.selectionNumberList}>
              {isDrawResultsFetching &&
                <CircularProgress size={'30px'} sx={{ color: '#bbb' }} />
              }
              {listOfGridDrawn[0]?.winningNumbers.map(numb => (
                <Box key={numb} sx={style.selectionNumberBox}>
                  <Typography sx={style.selectionNumberText}>
                    {zeroFill(numb)}
                  </Typography>
                </Box>
              ))}
              {listOfGridDrawn.length > 1 && listOfGridDrawn[1].winningNumbers.map(numb => (
                <Box key={numb} sx={style.selectionNumberExtraGridBox}>
                  <Typography sx={style.selectionNumberText}>
                    {zeroFill(numb)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </>
        }

      </Box>

      <Box sx={style.detailsContainer}>
        <Button
          sx={style.detailsContainerExpandButton}
          onClick={toggleDetails}
          disableRipple
        >
          <Typography sx={style.detailsContainerExpandButtonText}>
            {t('Bet.details')}
          </Typography>
          <Box sx={[style.detailsContainerIcon, showDetails && style.detailsContainerIconOpen]}>
            <SVGChevronDown width="12" height="12" stroke="#FFF" />
          </Box>
        </Button>
        {showDetails &&
          <Box sx={style.detailsContent}>
            <Box sx={style.detailsLineList}>
              <Box sx={style.detailsLine}>
                <Typography sx={style.detailsLineLabel}>
                  {t('stake')}
                </Typography>
                <Typography sx={style.detailsLineValue}>
                  {betDetails.stake.toLocaleString()} {currency}
                </Typography>
              </Box>
              <Box sx={style.detailsLine}>
                <Typography sx={style.detailsLineLabel}>
                  {t('Ticket.totalCost')}
                </Typography>
                <Typography sx={style.detailsLineValue}>
                  {betDetails.price.toLocaleString()} {currency}
                </Typography>
              </Box>
              {useTaxes &&
                <Box sx={style.detailsLine}>
                  <Box sx={style.detailsBoxTaxLabel}>
                    <Typography sx={style.detailsLineTaxLabel}>
                      {t('tax')}
                    </Typography>
                    <Button disableRipple onClick={openTaxesPopup} sx={{minWidth: '0px', padding: '0px'}}>
                      <SVGInfo fill="#F79009" width="16" height="16" />
                    </Button>
                  </Box>
                  <Typography sx={[style.detailsLineValue, style.detailsLineTaxValue]}>
                    {taxAmount === 0 ? 0 : -taxAmount} {currency}
                  </Typography>
                </Box>
              }
              <Box sx={style.detailsLine}>
                <Typography sx={style.detailsLineLabel}>
                  {t('Ticket.refTicket')}
                </Typography>
                <Tooltip
                  title={item.gameTicketReference}
                  arrow
                  placement="top-end"
                  open={openTooltip && isMobile}
                  PopperProps={{ sx: style.toolTip }}
                >
                  <Button disableRipple sx={style.buttonTooltip} onClick={() => setOpenTooltip(!openTooltip)}>
                    <Typography noWrap={true} sx={[style.detailsLineValue, { color: "#878787", fontSize: '12px', textTransform: 'none' }]}>
                      {item.gameTicketReference}
                    </Typography>
                  </Button>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={[style.detailsLine, style.detailsLineStrong]}>
              <Typography sx={style.detailsLineStrongLabel}>
                {t('Bet.max_gain')}
              </Typography>
              <Typography sx={[style.detailsLineValue, style.detailsLineStrongValue]}>
                {maxNetAmount.toLocaleString()} {currency}
              </Typography>
            </Box>
          </Box>
        }
      </Box>

      {errorTextKey.length > 0 &&
        <>
          <Typography sx={style.errorText}>
            *{t(errorTextKey)}
          </Typography>
          <Button onClick={() => setOpenSupportPopup(true)} disableRipple sx={style.errorButton}>
            <Typography sx={style.errorButtonTxt}>
              {t('Ticket.contact_support')}
            </Typography>
          </Button>
        </>
      }

      <SupportPopup
        open={openSupportPopup}
        onClose={() => setOpenSupportPopup(false)}
        buttonText={t("understood")}
        bookingId={item.bookingId}
        gameTicketReference={item.gameTicketReference}
      />

      <TaxesPopup
        open={showTaxesPopup}
        onClose={() => setShowTaxesPopup(false)}
        buttonText={t("understood")}
      />

    </Box>
  );
}

export default BetItem;
