import { IDraw, IDrawListByDate, TDrawsAsObject } from 'src/models/lottery/draw.model';
import { ENV } from '../../env';
import kp_axios, { PLATFORM_LOTTERY } from './api.koralplay.service';
import dayjs from 'dayjs';
import { IBetType, IBetTypesByCategory } from 'src/models/lottery/betType.models';

//=============================================================================
// API CALLS
//=============================================================================

const ENGINE_URL = ENV.API_URL + ENV.ENGINE_PATH;

// GAMES

export const getGame = async (gameId: number | string) => {
  let params = {};
  return kp_axios.get(`${ENGINE_URL}/v2-a/webplayer/games/${gameId}`, { params });
}

// BET TYPES

export const getInstantBetTypeList = async (gameId: number) => {
  let params = {
    platform: PLATFORM_LOTTERY,
  };
  return kp_axios.get(`${ENGINE_URL}/v2-b/webplayer/games/${gameId}/lottery/instant/betTypes`, { params });
}

// SESSIONS

export const getInstantSessionsFuture = async (gameId: number, max?: number) => {
  let params = { max, timeZone: dayjs.tz.guess() };
  return kp_axios.get(`${ENGINE_URL}/v2-a/webplayer/games/${gameId}/lottery/instant/draws/future`, { params });
}

//=============================================================================
// UTILS 
//=============================================================================

// SESSIONS

export const isExpiredSession = (draw: IDraw): boolean => {
  return dayjs().isAfter(dayjs(draw.closingDate));
}
export const findExpiredSessions = (list: IDraw[]): IDraw[] => {
  const now = dayjs();
  return list.filter(draw => now.isAfter(dayjs(draw.closingDate)));
}
export const removeExpiredSessions = (list: IDraw[]): IDraw[] => {
  const now = dayjs();
  return list.filter(draw => now.isBefore(dayjs(draw.closingDate)));
}
export const sessionListToObject = (list: IDraw[]): TDrawsAsObject => {
  return list.reduce((acc, cur) => ({ ...acc, [cur.drawId]: cur }), {} as TDrawsAsObject);
}

export const groupSessionsByDate = (data: IDraw[]): IDrawListByDate[] => {

  const groupedData = data.reduce((acc, entry) => {
    const date = entry.drawingDate.split('T')[0];
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push({ ...entry })
    return acc;
  }, {});

  const result: IDrawListByDate[] = Object.keys(groupedData).map(date => ({
    date: date,
    sessions: groupedData[date]
  }));

  return result;
}

//BETTYPES

export const groupBetTypesByCategory = (betTypes: IBetType[]): IBetTypesByCategory[] => {

  const groupedBets: { [key: string]: IBetType[] } = {};
  betTypes.forEach(betType => {
    const newCategory = betType?.category ?? 'others';
    if (!groupedBets[newCategory]) {
      groupedBets[newCategory] = [];
    }
    groupedBets[newCategory].push(betType);
  });

  let result: IBetTypesByCategory[] = Object.keys(groupedBets).map(category => ({
    category,
    betTypes: groupedBets[category].sort(sortBetTypes),
  })).sort((a, b) => a.category < b.category ? -1 : 1);

  return result;
}

const sortBetTypes = (a: IBetType, b: IBetType): number => {
  // sort by category asc, then displayOrder asc (null displayOrder at the end), then initial order
  return a.category < b.category ? -1 : a.category > b.category ? 1 :
  a.displayOrder == null && b.displayOrder == null ? 0 /*keep initial order*/ :
    b.displayOrder == null ? -1 : a.displayOrder == null ? 1 :
      a.displayOrder - b.displayOrder > 0 ? 1 : a.displayOrder - b.displayOrder < 0 ? -1
        : 0 /*keep initial order*/;
}
