import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translationFR } from './translation.fr';
import { translationEN } from './translation.en';
import { translationPT } from './translation.pt';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const getI18n = () => {

  const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
  };

  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: { translation: translationEN },
        fr: { translation: translationFR },
        pt: { translation: translationPT }
      },
      detection: DETECTION_OPTIONS,
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false
      }
    });
  return i18n;
}

export const timeZone = dayjs.tz.guess();

export const setLanguage = (lang) => {
  console.log("language used is " + lang)
  i18n.changeLanguage(lang)
  dayjs.locale(lang);
}

export default getI18n();