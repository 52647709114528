import * as service from "../services/game.service";

// Definition of the actions name

export const ACTIONS = {
  GAME_ERROR: 'GAME_ERROR',
  GAME_FETCHING: 'GAME_FETCHING',
  GAME_FETCHING_DONE: 'GAME_FETCHING_DONE',
  BETTYPES_ERROR: 'BETTYPES_ERROR',
  BETTYPES_FETCHING: 'BETTYPES_FETCHING',
  BETTYPES_FETCHING_DONE: 'BETTYPES_FETCHING_DONE',
  CURRENT_SESSIONS_ERROR: 'CURRENT_SESSIONS_ERROR',
  CURRENT_SESSIONS_FETCHING: 'CURRENT_SESSIONS_FETCHING',
  CURRENT_SESSIONS_FETCHING_DONE: 'CURRENT_SESSIONS_FETCHING_DONE',
  //
  GAME_BETTYPES: 'GAME_BETTYPES',
  GAME_INFO: 'GAME_INFO',
  GAME_CURRENT_SESSIONS: 'GAME_CURRENT_SESSIONS',
}

// Dispatchers

// Lifecycle
export const set_state_error = (err: any) => {
  return { type: ACTIONS.GAME_ERROR, payload: err };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.GAME_FETCHING };
}
export const set_state_fetching_done = () => {
  return { type: ACTIONS.GAME_FETCHING_DONE };
}
//
export const set_state_bettypes_error = (err: any) => {
  return { type: ACTIONS.BETTYPES_ERROR, payload: err };
}
export const set_state_bettypes_fetching = () => {
  return { type: ACTIONS.BETTYPES_FETCHING };
}
export const set_state_bettypes_fetching_done = () => {
  return { type: ACTIONS.BETTYPES_FETCHING_DONE };
}
//
//
export const set_state_currentSessions_error = (err: any) => {
  return { type: ACTIONS.CURRENT_SESSIONS_ERROR, payload: err };
}
export const set_state_currentSessions_fetching = () => {
  return { type: ACTIONS.CURRENT_SESSIONS_FETCHING };
}
export const set_state_currentSessions_fetching_done = () => {
  return { type: ACTIONS.CURRENT_SESSIONS_FETCHING_DONE };
}
//
export const set_game = (game: any) => {
  return { type: ACTIONS.GAME_INFO, payload: game };
}
export const set_current_sessions = (list: any[]) => {
  return { type: ACTIONS.GAME_CURRENT_SESSIONS, payload: list };
}
export const set_bettype_list = (betTypes: any[]) => {
  return { type: ACTIONS.GAME_BETTYPES, payload: betTypes };
}

// GAMES

export const getGame = (gameId: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getGame(gameId);
      if (r.data?.game) {
        dispatch(set_game(r.data?.game));
        dispatch(set_state_fetching_done());
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

// BET TYPES

export const getBetTypeList = (gameType, gameId) => {
  if (gameType == 'instantLottery')
    return getInstantBetTypeList(gameId);
  else
    return getMutualBetTypeList(gameId);
}

export const getInstantBetTypeList = (gameId: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(set_state_bettypes_fetching());
      let r = await service.getInstantBetTypeList(gameId);
      if (r.data?.betTypes?.length > 0) {
        dispatch(set_bettype_list(r.data?.betTypes))
      }
      else throw new Error("no betType");
      dispatch(set_state_bettypes_fetching_done());
    } catch (err) {
      dispatch(set_state_bettypes_error(err));
    }
  }
}

export const getMutualBetTypeList = (gameId: number) => {
  return async (dispatch: any) => {
    return Promise.resolve();
  }
}

// SESSIONS

export const getSessionsFuture = (gameType, gameId) => {
  if (gameType == 'instantLottery')
    return getInstantSessionsFuture(gameId);
  else
    return getMutualSessionsFuture(gameId);
}

export const getInstantSessionsFuture = (gameId: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(set_state_currentSessions_fetching());
      let r = await service.getInstantSessionsFuture(gameId);
      dispatch(set_current_sessions(r.data?.listOfDraws))
      dispatch(set_state_currentSessions_fetching_done());
    } catch (err) {
      dispatch(set_state_currentSessions_error(err));
    }
  }
}

export const getMutualSessionsFuture = (gameId: number) => {
  return async (dispatch: any) => {
    return Promise.resolve();
  }
}

export const sortListByClosingDate = (itemList: any) => {
  return itemList.sort((a, b) => {
    const dateA = new Date(a.drawingDate).getTime();
    const dateB = new Date(b.drawingDate).getTime();
    return dateA - dateB;
  })
}