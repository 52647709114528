import * as service from 'src/store/services/taxes.service';
import { cache_expired, cache_reset_from_model } from '../services/cache.service';

export const ACTIONS = {
  TAXES_FETCHING: 'TAXES_FETCHING',
  TAXES_CONFIG: 'TAXES_CONFIG',
  TAXES_NONE: 'TAXES_NONE',
}

export const set_taxes_config = (taxList) => {
  return { type: ACTIONS.TAXES_CONFIG, payload: taxList };
}

export const set_no_taxes = () => {
  return { type: ACTIONS.TAXES_NONE };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.TAXES_FETCHING };
}

export const loadTaxes = () => {
  return async dispatch => {
    try {
      if (cache_expired(service.tax_api_cache.key)) {
        dispatch(set_state_fetching());
        let r = await service.loadTaxes();
        if (r.data?.success) {
          dispatch(set_taxes_config(r.data?.listOfTaxLevel || []))
        } else {
          dispatch(set_no_taxes())
        }
        cache_reset_from_model(service.tax_api_cache);
      }
    } catch (err) {
      dispatch(set_no_taxes());
    }
  }
}