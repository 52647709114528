import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  headerContainer: {
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end'
    },
    padding: '16px',
  },
  button: {
    height: '40px',
    borderRadius: '16px',
    border: 'solid 1px rgba(0, 0, 0, 0.25)',
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.05)',
    marginLeft: '16px',
    width: '135px',
    "&:active": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
      minWidth: '40px',
      width: '40px',
    },
  },
  buttonActive: {
    background: theme.palette.primary.main,
    border: 'none',
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonText: {
    color: '#272727',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    marginLeft: '8px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  buttonTextActive: {
    color: '#FFF'
  },
  basketCounter: {
    backgroundColor: '#F44040',
    borderRadius: '50%',
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    position: 'absolute',
    top: '-4px',
    right: '-6px',
    width: '20px',
    height: '20px',
    [theme.breakpoints.up('md')]: {
      bottom: 'unset',
      right: '6px',
    },
  },
  exitButton: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '16px',
      left: '16px',
    }
  },
})