import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  boxButton: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px'
  },
  button: {
    flex: 1,
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    paddingLeft: '10px',
    paddingRight: '10px',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 700,
    borderRadius: '8px',
    '& :hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  dismissButton: {
    flex: 1,
    backgroundColor: 'transparent',
    color: "#272727",
    border: 'solid 1px',
    '&:hover': {
      backgroundColor: '#C4D7F8',
      border: 'solid 1px'
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '32px',
    maxWidth: '400px'
  },
  popover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
    backgroundColor: 'rgba(43 43 43 / 50%)',
    '& .MuiPaper-root': {
      borderRadius: '8px',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '16px',
      maxWidth: '468px',
      padding: '24px',
      textAlign: 'center',
    }
  }
});