import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0, //scroll
    height: '100%',
  },
  pageLoading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  internalNavBar: {
    textAlign: 'left',
    paddingLeft: '4px',
  },
})