import * as React from "react";

const SVGWallet = ({ fill, width, height }: any) => {
  return (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.2222 17H3.77778C2.79594 17 2 16.2424 2 15.3077V7.69231C2 6.75767 2.79594 6 3.77778 6H16.2222C17.2041 6 18 6.75767 18 7.69231V15.3077C18 16.2424 17.2041 17 16.2222 17Z" stroke={fill} strokeWidth="1.5"/>
  <path d="M13.7499 11.6667C13.5198 11.6667 13.3333 11.4801 13.3333 11.25C13.3333 11.02 13.5198 10.8334 13.7499 10.8334C13.98 10.8334 14.1666 11.02 14.1666 11.25C14.1666 11.4801 13.98 11.6667 13.7499 11.6667Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M15 6.14742V4.85359C15 3.63639 13.9205 2.74994 12.8201 3.06356L3.28671 5.78069C2.52795 5.99695 2 6.73141 2 7.57072V8" stroke={fill} strokeWidth="1.5"/>
  </svg>
  )
}
export default SVGWallet;