import * as React from "react";

const SVGError = ({ fill, width, height }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="205" height="205" viewBox="0 0 205 205" fill="none">
      <circle cx="102.5" cy="102.5" r="102.5" fill="url(#paint0_linear_954_29214)"/>
      <mask id="mask0_954_29214" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="205" height="205">
        <circle cx="102.5" cy="102.5" r="102.5" fill="url(#paint1_linear_954_29214)"/>
      </mask>
      <g mask="url(#mask0_954_29214)">
        <path d="M48.5 190L43 141L82.5 106L105 23.5L218 123.5C214 139.167 205.8 170.8 205 172C204.2 173.2 188 187.833 180 195L123.5 217.5L69.5 215.5L48.5 190Z" fill="url(#paint2_linear_954_29214)"/>
      </g>
      <g filter="url(#filter0_d_954_29214)">
        <path d="M40 136.016V128.148L165 125V136.803C165 138.692 162.904 140.738 161.855 141.525L114.686 169.852C113.113 170.902 108.553 173 102.893 173C97.2327 173 92.1488 170.902 90.3145 169.852C75.6394 161.197 45.6604 143.413 43.1447 141.525C40.6289 139.636 40 137.066 40 136.016Z" fill="url(#paint3_linear_954_29214)"/>
      </g>
      <g filter="url(#filter1_d_954_29214)">
        <path d="M44.0691 122.447L75.9916 103.079L128.936 104.628L161.637 120.898C166.62 124.617 164.232 129.162 162.416 130.969C147.363 140.266 115.855 159.79 110.25 163.508C104.644 167.227 97.5324 165.058 94.6776 163.508L43.2905 133.294C37.6846 128.955 41.4738 124.255 44.0691 122.447Z" fill="url(#paint4_linear_954_29214)"/>
      </g>
      <g filter="url(#filter2_d_954_29214)">
        <path d="M101.301 22.8995L68.6553 128.061C97.8808 146.133 126.951 135.591 137.833 128.061C127.728 94.565 107.053 26.6386 105.187 22.8995C104.41 21.3415 101.301 22.1205 101.301 22.8995Z" fill="url(#paint5_radial_954_29214)"/>
      </g>
      <defs>
        <filter id="filter0_d_954_29214" x="28" y="119" width="149" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="6"/>
          <feGaussianBlur stdDeviation="6"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_954_29214"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_954_29214" result="shape"/>
        </filter>
        <filter id="filter1_d_954_29214" x="28.3887" y="97.0788" width="148.222" height="86.4828" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="6"/>
          <feGaussianBlur stdDeviation="6"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_954_29214"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_954_29214" result="shape"/>
        </filter>
        <filter id="filter2_d_954_29214" x="56.6553" y="16" width="93.1777" height="140.039" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="6"/>
          <feGaussianBlur stdDeviation="6"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_954_29214"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_954_29214" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_954_29214" x1="48.5" y1="43.5" x2="215.7" y2="186.513" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D82727"/>
          <stop offset="1" stopColor="#E87D7D"/>
        </linearGradient>
        <linearGradient id="paint1_linear_954_29214" x1="2.3738" y1="53.7822" x2="206.366" y2="134.505" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5827D8"/>
          <stop offset="1" stopColor="#9B7DE8"/>
        </linearGradient>
        <linearGradient id="paint2_linear_954_29214" x1="81.9975" y1="56.2763" x2="216.402" y2="173.879" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B82828"/>
          <stop offset="0.878739" stopColor="#D02F2F"/>
        </linearGradient>
        <linearGradient id="paint3_linear_954_29214" x1="90.3641" y1="135.597" x2="102.528" y2="166.167" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC7979"/>
          <stop offset="1" stopColor="#A71F1F"/>
        </linearGradient>
        <linearGradient id="paint4_linear_954_29214" x1="65.4995" y1="110" x2="112.11" y2="166.855" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4D7D7"/>
          <stop offset="1" stopColor="#DE4F4F"/>
        </linearGradient>
        <radialGradient id="paint5_radial_954_29214" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(84.5 66) rotate(61.8584) scale(48.7647 65.3706)">
          <stop stopColor="#F4D7D7"/>
          <stop offset="1" stopColor="#E67A7A"/>
        </radialGradient>
      </defs>
    </svg>
  )
}
export default SVGError;