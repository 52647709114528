import { RouteObject } from "react-router-dom";
import BetBuilderPage from "./pages/lottery/betBuilder/betBuilder.page";
import MyBetsPage from "./pages/ticketing/myBets/myBets.page";
import MyWalletPage from "./pages/lottery/wallet/myWallet.page";
import GameRulesHomePage from "./pages/lottery/gameRules/gameRulesHome.page";
import BasketPage from "./pages/lottery/basket/basket.page";
import AppLoaderPage from "./pages/core/appLoader.page";
import BookingSubmitPage from "./pages/ticketing/booking/bookingSubmit.page";
import App from "./App";
import ErrorPage from "./pages/core/error/error.page";
import BetTypeRulesPage from "./pages/lottery/gameRules/betTypeRules.page";

export const routeAttr = {
  betTypeId: ':betTypeId',  
}

export const routePath = {
  initApp: '/gamesprovider/lottery/',
  betBuilder: '/betBuilder',
  basket: '/basket',
  betTypeRules: `/info/betType/${routeAttr.betTypeId}`,
  bookingSubmit: "/bookingSubmit",
  error: '/error',
  info: '/info',
  myBets: '/myBets',
  myWallet: "/myWallet",
}



export const routes: RouteObject[] = [
  {
    path: "/gamesprovider/lottery/:gameId",
    element: <AppLoaderPage />,
  },
  {
    path: routePath.bookingSubmit,
    element: <BookingSubmitPage />,
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: routePath.error,
        element: <ErrorPage />,
      },
      {
        path: routePath.basket,
        element: <BasketPage />,
      },
      {
        path: routePath.betBuilder,
        element: <BetBuilderPage />,
      },
      {
        path: routePath.betTypeRules,
        element: <BetTypeRulesPage />,
      },
      {
        path: routePath.info,
        element: <GameRulesHomePage />,
      },
      {
        path: routePath.myBets,
        element: <MyBetsPage />,
      },
      {
        path: routePath.myWallet,
        element: <MyWalletPage />,
      },
    ]
  },
]