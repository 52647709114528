const SVGGameRules = ({ width, height }: any) => {
  return (
    <svg width={width || 116} height={height || 116} viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">

      <rect width="100.701" height="100.438" rx="34.0886" transform="matrix(0.985704 0.168488 -0.166658 0.986015 16.7388 0)" fill="#FFE3B8" />
      <rect width="98.6129" height="97.4816" rx="34.0886" transform="matrix(0.999896 0.0144454 -0.0142841 0.999898 8.78467 7.90137)" fill="url(#paint0_linear_2198_85069)" />
      <mask id="mask0_2198_85069" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="7" y="8" width="100" height="99">
        <rect width="98.6129" height="97.4816" rx="34.0886" transform="matrix(0.999896 0.0144454 -0.0142841 0.999898 8.87646 7.90137)" fill="url(#paint1_linear_2198_85069)" />
      </mask>
      <g mask="url(#mask0_2198_85069)">
        <path d="M32.3663 107.113L11.7856 34.1451L56.6889 9.35474L114.689 61.7418L108.14 107.581L32.3663 107.113Z" fill="url(#paint2_linear_2198_85069)" />
      </g>
      <circle cx="75.7747" cy="91.3267" r="3.74194" fill="#D9D9D9" />
      <rect x="7.48486" y="29.0085" width="51.9244" height="94.2621" rx="8" transform="rotate(-30.3059 7.48486 29.0085)" fill="white" />
      <rect x="10.5112" y="30.2661" width="47.6966" height="80.6243" rx="4" transform="rotate(-30.3059 10.5112 30.2661)" fill="url(#paint3_linear_2198_85069)" />
      <rect x="29.5308" y="52.3979" width="11.2258" height="11.2258" rx="2" transform="rotate(-30 29.5308 52.3979)" fill="white" />
      <rect x="37.6411" y="65.4949" width="11.2258" height="11.2258" rx="2" transform="rotate(-30 37.6411 65.4949)" fill="white" />
      <rect x="45.1255" y="78.5916" width="11.2258" height="11.2258" rx="2" transform="rotate(-30 45.1255 78.5916)" fill="white" />
      <rect x="21.7378" y="38.3657" width="11.2258" height="11.2258" rx="2" transform="rotate(-30 21.7378 38.3657)" fill="white" />
      <rect x="44.1899" y="48.9192" width="21.5207" height="1.87097" rx="0.935484" transform="rotate(-30.4771 44.1899 48.9192)" fill="white" />
      <rect x="52.3003" y="62.0161" width="21.5207" height="1.87097" rx="0.935484" transform="rotate(-30.4771 52.3003 62.0161)" fill="white" />
      <rect x="61.0278" y="77.9192" width="21.5207" height="1.87097" rx="0.935484" transform="rotate(-30.4771 61.0278 77.9192)" fill="white" />
      <rect x="59.1577" y="74.1772" width="14.5947" height="1.87097" rx="0.935484" transform="rotate(-30.4771 59.1577 74.1772)" fill="white" />
      <rect x="36.397" y="34.887" width="21.5207" height="1.87097" rx="0.935484" transform="rotate(-30.4771 36.397 34.887)" fill="white" />
      <rect x="94.6753" y="40.5112" width="5.50953" height="3.17093" rx="1.58547" transform="rotate(-27.4582 94.6753 40.5112)" fill="#FDD470" />
      <rect x="97.2915" y="67.3547" width="14.9677" height="3.74194" rx="1.87097" fill="white" />
      <rect x="72.0327" y="26.7883" width="12.569" height="5.6129" rx="2.80645" transform="rotate(-60 72.0327 26.7883)" fill="white" />
      <rect x="0.312988" y="81.5898" width="18.6988" height="4.38824" rx="2.19412" transform="rotate(-27.4582 0.312988 81.5898)" fill="white" />
      <rect x="33.2358" y="104.914" width="8.80328" height="3.62884" rx="1.81442" transform="rotate(-60 33.2358 104.914)" fill="#FDD470" />
      <rect x="2.80713" y="53.3223" width="8.41935" height="3.74194" rx="1.87097" fill="#FDD470" />
      <defs>
        <linearGradient id="paint0_linear_2198_85069" x1="3.96682" y1="17.5431" x2="97.3105" y2="90.336" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBF4D" />
          <stop offset="0.301202" stopColor="#FF996C" />
          <stop offset="0.671173" stopColor="#FD87A1" />
          <stop offset="1" stopColor="#B965EF" />
        </linearGradient>
        <linearGradient id="paint1_linear_2198_85069" x1="1.14189" y1="25.5745" x2="98.9609" y2="64.7324" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5827D8" />
          <stop offset="1" stopColor="#9B7DE8" />
        </linearGradient>
        <linearGradient id="paint2_linear_2198_85069" x1="32.1414" y1="30.0029" x2="111.497" y2="105.655" gradientUnits="userSpaceOnUse">
          <stop offset="0.288863" stopOpacity="0.6" />
          <stop offset="0.782491" stopOpacity="0.15" />
        </linearGradient>
        <linearGradient id="paint3_linear_2198_85069" x1="13.7643" y1="33.5968" x2="75.9392" y2="71.8115" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC64B" />
          <stop offset="0.299688" stopColor="#FFA157" />
          <stop offset="0.644498" stopColor="#FF889F" />
          <stop offset="1" stopColor="#B764F2" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default SVGGameRules;