import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import * as BetBuilderActions from "src/store/actions/betBuilder.actions";
import { Box, Button, Typography, useTheme, Grid } from "@mui/material";
import { makeStyle } from "./consecutiveDrawsPicker.style";
import SVGDrawArrow from "src/assets/icons/SVGDrawArrow";

const ConsecutiveDrawsPicker = ({
  goNextStep,
  goPreviousStep,
}) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch();

  //====================================== States

  const maxDrawByBet = useSelector((state: RootState) => state.betBuilder.selectedBetType.maxDrawByBet);
  const selectedBetType = useSelector((state: RootState) => state.betBuilder.selectedBetType);
  const totalPrice = useSelector((state: RootState) => state.betBuilder.totalPrice);
  const betBuilderData = useSelector((state: RootState) => state.betBuilder);
  const selectedConsecutiveDrawCount = useSelector((state: RootState) => state.betBuilder.selectedConsecutiveDrawCount);
  const selectedStake = useSelector((state: RootState) => state.betBuilder.selectedStake);
  const currency: string = useSelector((state: RootState) => state.player.playerInfo.currencyId);
  const currentSessions = useSelector((state: RootState) => state.game.currentSessions);
  const staggeredStake = useSelector((state: RootState) => state.app.config.betting.staggered_stake) || 1;


  const [totalPriceError, setTotalPriceError] = useState('');
  const [stakeError, setStakeError] = useState('');

  //====================================== Effects

  useEffect(() => {
    checkTotalPriceError();
  }, [betBuilderData.totalPrice]);

  useEffect(() => {
    dispatch(BetBuilderActions.set_totalPrice(selectedStake * selectedConsecutiveDrawCount * (betBuilderData.combinationCount || 1)))
  }, [selectedConsecutiveDrawCount])

  useEffect(() => {
    checkStakeValid(selectedStake);
  }, [selectedStake])

  //====================================== Functions

  const checkTotalPriceError = () => {
    let _error = '';
    if (totalPrice > selectedBetType?.maxBetsTotalPrice) {
      _error = t('Stake.error_total_price_exceeded', { N: selectedBetType.maxBetsTotalPrice.toLocaleString() + ' ' + currency });
    }
    setTotalPriceError(_error);
  }

  const checkStakeValid = (stake: number) => {
    let _error = '';
    if (stake < selectedBetType.minBetAmount) {
      _error = t('Stake.error_amount_too_low', { N: selectedBetType.minBetAmount, currency });
    }
    else if (stake > selectedBetType.maxBetAmount) {
      _error = t('Stake.error_amount_too_high', { N: selectedBetType.maxBetAmount, currency });
    }
    else if (stake % staggeredStake != 0) {
      _error = t('Stake.error_amount_staggering', { N: staggeredStake });
    }
    setStakeError(_error);
  }

  const onValidateDrawCount = () => {
    //Attach the first draw to the bet
    if (betBuilderData.selectedSessionCount == 0) {
      const nextDraw = currentSessions[0];
      nextDraw && BetBuilderActions.set_sessions_selected({ [nextDraw.drawId]: nextDraw });
    }
    goNextStep()
  }

  const onAddADraw = () => {
    selectedConsecutiveDrawCount < Math.min(maxDrawByBet, currentSessions.length) && dispatch(BetBuilderActions.set_consecutive_draws(selectedConsecutiveDrawCount + 1));
  }

  const onRemoveADraw = () => {
    selectedConsecutiveDrawCount > 1 && dispatch(BetBuilderActions.set_consecutive_draws(selectedConsecutiveDrawCount - 1));
  }

  const onAddMaxDraw = () => {
    dispatch(BetBuilderActions.set_consecutive_draws(Math.min(maxDrawByBet, currentSessions.length)));
  }

  //====================================== Render

  //--
  return (
    <Box id="draw-picker" sx={style.container}>

      <Grid container sx={style.header}>
        <Grid item xs={12}>
          <Box sx={style.headerBox}>
            <Typography sx={style.headerText}>
              {t('draws')}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box sx={style.body}>

        <Box sx={style.drawsSelectBox}>
          <Button
            disableRipple
            onClick={onAddADraw}
            sx={style.drawsSelectBtn}
          >
            <SVGDrawArrow />
          </Button>
          <Typography sx={style.drawsSelectCount}>{selectedConsecutiveDrawCount}</Typography>
          <Button
            disableRipple
            onClick={onRemoveADraw}
            sx={style.drawsSelectBtn}
          >
            <Box sx={{ transform: 'rotate(180deg)' }}>
              <SVGDrawArrow />
            </Box>
          </Button>
        </Box>

        <Box sx={style.buttonRow}>
          <Button
            sx={[style.actionButton, style.maxDrawButton]}
            onClick={onAddMaxDraw}
            disableRipple
          >
            <Typography sx={style.actionButtonText}>
              {t('Stake.max_draws')}
            </Typography>
          </Button>
        </Box>

        {totalPriceError &&
          <Typography sx={style.stakeErrorText}>
            *{totalPriceError}
          </Typography>
        }

        <Box>
          <Box sx={style.ticketPriceRecap}>
            <Typography sx={style.ticketPriceRecapTxt1}>{t('Stake.unit_ticket_price')}:</Typography>
            <Typography sx={style.ticketPriceRecapTxt2}>{selectedStake} {currency}</Typography>
          </Box>
          <Box sx={style.ticketPriceRecap}>
            <Typography sx={style.ticketPriceRecapTxt1}>{t('Total')}:</Typography>
            <Typography sx={[style.ticketPriceRecapTxt2, { fontSize: 24 }]}>{totalPrice} {currency}</Typography>
          </Box>
        </Box>

        <Box sx={style.validateContainer}>
          <Box sx={style.buttonRow}>
            <Button
              sx={[style.actionButton, style.validateButton]}
              onClick={onValidateDrawCount}
              disableRipple
              disabled={stakeError.length > 0 || totalPriceError.length > 0}
            >
              <Typography sx={style.actionButtonText}>
                {t('Stake.validate_amount')}
              </Typography>
            </Button>
          </Box>
        </Box>

      </Box>
    </Box>
  )
}

//======================================
export default ConsecutiveDrawsPicker;
