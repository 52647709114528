export const ENV = {
  APP_VERSION: process.env.REACT_APP_VERSION,
  APP_NAME: process.env.REACT_APP_NAME,
  ENV: process.env.REACT_APP_ENV,
  KEYCLOAK_AUTH_URL: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  KEYCLOAK_USERNAME: process.env.REACT_APP_KEYCLOAK_USERNAME,
  KEYCLOAK_PASSWORD: process.env.REACT_APP_KEYCLOAK_PASSWORD,
  ENGINE_PATH: process.env.REACT_APP_ENGINE_ENDPOINT,
  API_URL: process.env.REACT_APP_API_URL?.replace(/\/$/, '') || "",
  STORAGE_URL: process.env.REACT_APP_STORAGE_URL,
}