import Box from '@mui/material/Box';
import { Typography, useTheme, Popover, Button } from '@mui/material';
import { makeStyle } from './alertPopup.style';
import { ReactNode } from 'react';

type Props = {
  onClose: () => void;
  open: boolean;
  buttonText?: string;
  children?: ReactNode;
  hideBackdrop?: boolean;
  title?: string;
}

function AlertPopup({
  onClose,
  open,
  buttonText,
  children,
  hideBackdrop,
  title,
}: Props): JSX.Element {

  const style = makeStyle(useTheme());

  return (
    <Popover
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop ?? true}
      disableScrollLock={true}
      anchorReference={"none"}
      sx={style.popover}
    >
      {title &&
        <Typography sx={style.title}>
          {title}
        </Typography>
      }

      {children}

      <Box sx={style.boxButton}>
        <Button
          variant="contained"
          disableRipple
          disableElevation
          sx={style.button}
          onClick={onClose}
        >
          {buttonText || 'OK'}
        </Button>
      </Box>
    </Popover>
  );
}

export default AlertPopup;
