export interface IGameRuleSlide {
  gameId: number;
  groupId: string | number;
  groupType: EGameRuleType;
  groupName?: string;
  slideOrder: number;
  lang: string; //ISO-2
  slideText: string;
  slideImage: string;
}

export interface IGameRuleSlideFilters {
  gameId?: number;
  groupId?: string | number;
  groupType?: EGameRuleType;
}

export enum EGameRuleType {
  game = 'game',
  betType = 'betType',
}
