import { Theme } from "@mui/material"


export const makeStyle = (theme: Theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '258px',
      display: 'flex',
      margin: 'auto',
      flexDirection: 'column',
    },
  },
  creationText1: {
    fontWeight: 700,
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  creationText2: {
    fontWeight: 700,
    fontSize: 20,
    color: '#272727'
  },
  errorBox: {
  },
  exitButtonContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    margin: '16px',
    backgroundColor: '#f3eded',
    borderRadius: '16px',
  },
  msgContainer: {
    display: 'flex',        
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '8px',
    background: '#FFF',
    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.08)',
    padding: '16px 24px 16px 20px',
    marginTop: '24px'
  }
})