import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  main: {
    inset: 0,
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default
  },
  mainContent: {
    padding: '5px',
    overflowY: 'auto',
  }
})

