// Definition of the actions name

import { IBetType } from "src/models/lottery/betType.models";
import { TGridSelectionsObj } from "src/models/lottery/grid.model";
import { RootStateFn } from "../root.reducer";
import { IDraw, TDrawsAsObject } from "src/models/lottery/draw.model";
import { EBasketStatus, IBasketItem } from "src/models/lottery/basket.model";
import { computeNetAmount } from "../services/taxes.service";
import { Game } from "src/models/lottery/game.model";
import { v4 as uuidv4 } from 'uuid';

export const ACTIONS = {
  BETBUILDER_BETTYPE_SELECTED: 'BETBUILDER_BETTYPE_SELECTED',
  BETBUILDER_CLEAR_SELECTED_GRID_NUMBERS: 'BETBUILDER_CLEAR_SELECTED_GRID_NUMBERS',
  BETBUILDER_CONSECUTIVE_DRAWS: 'BETBUILDER_CONSECUTIVE_DRAWS',
  BETBUILDER_GRIDS_SELECTED: 'BETBUILDER_GRIDS_SELECTED',
  BETBUILDER_RESET: 'BETBUILDER_RESET',
  BETBUILDER_SESSIONS_SELECTED: 'BETBUILDER_SESSIONS_SELECTED',
  BETBUILDER_STAKE_SELECTED: 'BETBUILDER_STAKE_SELECTED',
  BETBUILDER_TOTAL_PRICE: 'BETBUILDER_TOTAL_PRICE',
  BETBUILDER_MAX_GAIN: 'BETBUILDER_MAX_GAIN',
  BETBUILDER_PERMUTATION: 'BETBUILDER_PERMUTATION',
  BETBUILDER_CURRENT_STEP: 'BETBUILDER_CURRENT_STEP',
  BETBUILDER_FIRST_STEP: 'BETBUILDER_FIRST_STEP',
  BETBUILDER_EDITION_MODE: 'BETBUILDER_EDITION_MODE',
}

// LIFECYCLE

// SETTERS
export const set_sessions_selected = (sessions: TDrawsAsObject) => {
  return { type: ACTIONS.BETBUILDER_SESSIONS_SELECTED, payload: sessions };
}
export const set_current_step = (step) => {
  return { type: ACTIONS.BETBUILDER_CURRENT_STEP, payload: step };
}
export const set_first_step = (step) => {
  return { type: ACTIONS.BETBUILDER_FIRST_STEP, payload: step };
}
export const set_betType_selected = (betType) => {
  return { type: ACTIONS.BETBUILDER_BETTYPE_SELECTED, payload: betType };
}
export const set_grids_selected = (selectedGrids: TGridSelectionsObj) => {
  return { type: ACTIONS.BETBUILDER_GRIDS_SELECTED, payload: selectedGrids };
}
export const set_permutation = (data) => {
  return { type: ACTIONS.BETBUILDER_PERMUTATION, payload: data };
}
export const set_stake_selected = (stake) => {
  return { type: ACTIONS.BETBUILDER_STAKE_SELECTED, payload: stake };
}
export const set_consecutive_draws = (count) => {
  return { type: ACTIONS.BETBUILDER_CONSECUTIVE_DRAWS, payload: count };
}
export const set_totalPrice = (value) => {
  return { type: ACTIONS.BETBUILDER_TOTAL_PRICE, payload: value };
}
export const set_maxGain = (value) => {
  return { type: ACTIONS.BETBUILDER_MAX_GAIN, payload: value };
}
export const set_edition_mode = (value) => {
  return { type: ACTIONS.BETBUILDER_EDITION_MODE, payload: value };
}
export const clear_selected_grid_numbers = () => {
  return { type: ACTIONS.BETBUILDER_CLEAR_SELECTED_GRID_NUMBERS };
}
export const reset_data = () => {
  return { type: ACTIONS.BETBUILDER_RESET };
}

// Actions

/**
 * Update the `selectedGrids` state, for the given `gridName`
 * @param {*} gridName the name of the grid to update
 * @param {*} selectedNumbers the list of the numbers selected by the player
 */
export const updateSelectedGrid = (gridName: string, selectedNumbers: number[]) => {
  return (dispatch, getState) => {
    const current_selectedGrids = getState().betBuilder.selectedGrids;
    dispatch(set_grids_selected({
      ...current_selectedGrids,
      [gridName]: {
        ...current_selectedGrids[gridName],
        selectedNumbers,
      }
    }));
  }
}

export const updateSelectedGridFlash = (gridName: string, newNumber: number) => {
  return (dispatch, getState: RootStateFn) => {
    const current_selectedGrids = getState().betBuilder.selectedGrids;
    dispatch(set_grids_selected({
      ...current_selectedGrids,
      [gridName]: {
        ...current_selectedGrids[gridName],
        selectedNumbers: [...current_selectedGrids[gridName].selectedNumbers, newNumber],
      }
    }));
  }
}

/**
 * Initialize the state `selectedGrids` by using the `selectedBetType` config
 */
export const initializeSelectedGrids = (selectedBetType: IBetType) => {
  return (dispatch, getState: RootStateFn) => {
    const current_selectedGrids = Object.values(getState().betBuilder.selectedGrids);
    const hasNumbersSelected = current_selectedGrids[0]?.selectedNumbers?.length > 0;
    if (hasNumbersSelected) {
      return; //don't reset, keep existing
    }
    const selectedGrids: TGridSelectionsObj = selectedBetType?.listOfGridParameters?.reduce((acc, grid) => {
      return {
        ...acc,
        [grid.gridName]:
        {
          ...grid,
          ...selectedBetType.listOfGrids.find(elem => elem.gridName === grid.gridName),
          selectedNumbers: [],
        }
      }
    }, {});
    dispatch(set_grids_selected(selectedGrids));
  }
}

// Functions (service)

/**
 * Convert the pending bet to a [list of] basket item
 * @returns a list of basket items (1 for each selected session - except consecutiveDraws)
 * @see basket.actions.js > typeBasketItem
 */
export const toBasketItemList = () => {
  return (dispatch, getState: RootStateFn): IBasketItem[] => {
    const builder = getState().betBuilder;

    const pricePerItem = builder.totalPrice / (Object.keys(builder.selectedSessions).length || 1);
    
    let potentialAward = 0, potentialAwardNet = 0;
    if (getState().game.game.gameType == Game.GameTypeEnum.InstantLottery) {
      const maxStakeMultiplier = Math.max(...builder.selectedBetType.listOfPayLines.map(E => E.gainValue));
      potentialAward = pricePerItem * maxStakeMultiplier;
      potentialAwardNet = computeNetAmount(potentialAward, getState().taxes.taxLevels);
    }

    const basketItemBase: IBasketItem = {
      betId: '',
      betTypeId: builder.selectedBetType.betTypeId,
      betTypeName: builder.selectedBetType.name,
      combinationCount: builder.combinationCount,
      nbConsecutiveDraws: builder.selectedConsecutiveDrawCount || 0,
      price: pricePerItem,
      selectionListByGrid: Object.values(builder.selectedGrids).map(G => G.selectedNumbers),
      stake: builder.selectedStake,
      draw: {} as IDraw,
      drawId: '',
      potentialAward,
      potentialAwardNet,
      selectedBetType: builder.selectedBetType,
      status: EBasketStatus.valid,
      taxAmount: potentialAward - potentialAwardNet,
    };

    let itemList: IBasketItem[] = Object.values(builder.selectedSessions).map((draw: IDraw) => ({
      ...basketItemBase,
      draw,
      drawId: draw.drawId,
      betId: uuidv4(),
    }));

    if (Object.keys(builder.selectedSessions).length == 0 && builder.selectedBetType.maxDrawByBet > 1) {
      //Note: for the FAST-instant loteries, we select only the consecutive draw count
      itemList.push({
        ...basketItemBase,
        betId: uuidv4(),
        draw: {} as IDraw,
        drawId: '',
      })
    }
    return itemList;
  }
}