import { Box, Button, Collapse, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routeAttr, routePath } from "src/routes";
import { RootState } from "src/store/root.reducer";
import { makeStyle } from "./gameRulesHome.style";
import { useTranslation } from "react-i18next";
import SVGGameRules from "src/assets/icons/SVGGameRules";
import { useEffect, useState } from "react";
import { IBetType, IBetTypesByCategory } from "src/models/lottery/betType.models";
import SVGArrowForward from "src/assets/icons/SVGArrowForward";
import * as gameRulesActions from "src/store/actions/gameRules.actions";
import * as gameRulesService from "src/store/services/gameRules.service";
import { EGameRuleType, IGameRuleSlide } from "src/models/lottery/gameRules.model";
import { IGame } from "src/models/lottery/game.model";
import SVGArrowDown from "src/assets/icons/SVGArrowDown";

const GROUP_GAME_DESCRIPTION = 'group_game_description';

const GameRulesHomePage = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const theme = useTheme();
  const style = makeStyle(theme);

  //====================================== States

  const game: IGame = useSelector((state: RootState) => state.game.game);
  const betTypeList = useSelector((state: RootState) => state.game.betTypesActive);
  const betTypeListByCategory = useSelector((state: RootState) => state.game.betTypesByCategory);
  const gameRuleAllSlides: IGameRuleSlide[] = useSelector((state: RootState) => state.gameRules.allSlides);

  const [showCategories, setShowCategories] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [gameDescription, setGameDescription] = useState('');
  const [showGroupList, setShowGroupList] = useState(false);

  //====================================== Effects

  useEffect(() => {
    dispatch(gameRulesActions.loadGameRules());
  }, [])

  useEffect(() => {
    if (betTypeList.length == 0) {
      return;
    }
    if (gameRuleAllSlides.length > 0) {
      const gameRules = gameRulesService.findSlides(gameRuleAllSlides, { groupType: EGameRuleType.game, groupId: game?.gameId });
      const _gameDescription = gameRules[0]?.slideText || '';
      setGameDescription(_gameDescription);
      if (betTypeList.length == 1 && !_gameDescription) {
        navigate(routePath.betTypeRules.replace(routeAttr.betTypeId, betTypeList[0].betTypeId));
      }
    }
    setShowGroupList(true);
  }, [betTypeList, gameRuleAllSlides])

  useEffect(() => {
    setShowCategories(betTypeListByCategory?.length > 1);
  }, [betTypeListByCategory])

  //====================================== Functions

  const onBetTypeSelect = (betType: IBetType) => {
    navigate(routePath.betTypeRules.replace(routeAttr.betTypeId, betType.betTypeId));
  }

  const onGroupClick = (groupName: string) => {
    setSelectedGroup(selectedGroup == groupName ? '' : groupName);
  }

  //====================================== Render

  const renderBetTypeCategory = (data: IBetTypesByCategory, key: number) => (
    <Box
      key={key}
    >
      {showCategories &&
        <Button
          disableRipple
          sx={[style.betTypeCollapse, selectedGroup == data.category && style.betTypeCollapseOpen]}
          onClick={() => onGroupClick(data.category)}
        >
          <Typography sx={style.betTypeCollapseText}>
            {t(data.category)}
          </Typography>
          <Box sx={[style.groupArrowIcon, selectedGroup == data.category && style.groupArrowIconOpen]}>
            <SVGArrowDown width="24" height="24" stroke="#FFF" />
          </Box>
        </Button>
      }
      <Collapse in={!showCategories || selectedGroup == data.category} timeout="auto">
        {data.betTypes.map((betType, key2) => (
          renderBetTypeButton(betType, key2)
        ))}
      </Collapse>
    </Box>
  )

  const renderBetTypeButton = (betType: IBetType, key: number) => (
    <Box sx={style.betTypeButtonBox} key={key}>
      <Button disableRipple sx={style.betTypeButton} onClick={() => onBetTypeSelect(betType)}>
        <Typography sx={style.betTypeButtonText}>{betType.name}</Typography>
        <SVGArrowForward />
      </Button>
    </Box>
  )

  const renderGameDescriptionGroup = () => (
    <Box>
      <Button
        disableRipple
        sx={[style.betTypeCollapse, selectedGroup == GROUP_GAME_DESCRIPTION && style.betTypeCollapseOpen]}
        onClick={() => onGroupClick(GROUP_GAME_DESCRIPTION)}
      >
        <Typography sx={style.betTypeCollapseText}>
          {game?.name}, {t('GameRules.what_is_it').toLowerCase()}
        </Typography>
        <Box sx={[style.groupArrowIcon, selectedGroup == GROUP_GAME_DESCRIPTION && style.groupArrowIconOpen]}>
          {showCategories ? <SVGArrowDown width="24" height="24" stroke="#FFF" /> : <SVGArrowForward stroke="#FFF" />}
        </Box>
      </Button>
      <Collapse in={selectedGroup == GROUP_GAME_DESCRIPTION} timeout="auto">
        <Box sx={style.gameDescription}>
          {gameDescription}
        </Box>
      </Collapse>
    </Box>
  )

  return (
    <Box id="game-rules-home" sx={style.container}>

      {showGroupList && <>

        <Box sx={style.titleContainer}>
          <Typography sx={style.titleText}>{t('GameRules.game_rules')}</Typography>
          <SVGGameRules />
        </Box>

        <Box sx={style.ruleGroupList}>

          {gameDescription?.length > 0 &&
            renderGameDescriptionGroup()
          }

          {betTypeListByCategory.map((data, key) => (
            renderBetTypeCategory(data, key)
          ))}

        </Box>

      </>}

    </Box>
  )
}

export default GameRulesHomePage;