import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    marginLeft: '16px',
    marginRight: '16px'
  },
  topContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  errorContainer: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center'
  },
  errorText: {
    color: '#272727',
    textAlign: 'center',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    margin: '10px 24px',
    maxWidth: '280px'
  },
  errorStrongText: {
    color: '#F44040',
    textAlign: 'center',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    paddingRight: '4px',
  },
  
  errorButton: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    left: '16px',
    height: '48px',
    borderRadius: '16px',
    backgroundColor: '#F44040',
    "&:hover": {
      backgroundColor: "#F44040"
    },
    "&:active": {
      backgroundColor: "#F44040"
    },
    [theme.breakpoints.up('lg')]: {
      width: '350px',
      margin: 'auto',
      bottom: '130px',
    },
  },
  errorButtonText: {
    fontFamily: 'Inter',
    color: '#FFF',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'none'
  },
  actionMadeBox: {
    padding: '12px 16px',
    margin: '32px auto 0 auto',
    maxWidth: '270px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(0, 0, 0, 0.03)"
  },
  actionMadeText: {fontSize: '16px', fontWeight: 600}
})