/**
 * Compute the number of possible combinations to fill the slots, given a number of candidates. The order doesn't matter.
 * Ex: computeCombinations(7, 5) = how many configurations to fill 5 slots given 7 candidates.
 */
export const computeCombinations = (nbCandidates: number, nbSlots: number): number => {
  if (nbSlots > nbCandidates) {
    return 0;
  }
  /**
   * Formula: comb(nbCandidates, k) = nbCandidates! / k!(nbCandidates - k))!
   * We can use Pascal's triange to determine the combination count.
   * To calculate a single line: v(nbSlots) = (nbCandidates - nbSlots) / nbSlots
   * Since the triangle is symmetrical, we only need to calculate until nbSlots - column.
   */
  let v = nbCandidates--;
  for (let i = 2; i <= nbSlots; ++i, --nbCandidates) {
    v = v * nbCandidates / i;
  }
  return v;
}