import * as React from "react";

const SVGSession = ({ fill, width, height }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="116" height="116" viewBox="0 0 116 116" fill="none">
      <rect width="100.701" height="100.438" rx="34.0886" transform="matrix(0.985704 0.168488 -0.166658 0.986015 16.7388 0)" fill="#FFE3B8"/>
      <rect width="98.6129" height="97.4816" rx="34.0886" transform="matrix(0.999896 0.0144454 -0.0142841 0.999898 8.78418 7.90137)" fill="url(#paint0_linear_966_30795)"/>
      <mask id="mask0_966_30795" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="8" y="8" width="100" height="99">
        <rect width="98.6129" height="97.4816" rx="34.0886" transform="matrix(0.999896 0.0144454 -0.0142841 0.999898 9.39209 8)" fill="url(#paint1_linear_966_30795)"/>
      </mask>
      <g mask="url(#mask0_966_30795)">
        <path d="M46.4376 100.59C44.6153 98.07 39.9838 92.8588 37.8958 90.5681L34.1943 106.03L46.7223 106.316C47.3867 105.457 48.2598 103.109 46.4376 100.59Z" fill="url(#paint2_linear_966_30795)"/>
        <path d="M63.5214 106.316C62.838 104.713 58.1116 100.876 55.8337 99.1581C55.8337 101.735 55.7768 106.946 55.549 107.175C55.2643 107.462 64.3755 108.321 63.5214 106.316Z" fill="url(#paint3_linear_966_30795)"/>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M106.864 55.9088L106.624 72.7122C106.367 90.707 92.2122 105.243 74.5214 106.253C74.1981 106.165 73.8982 106.072 73.6242 105.973C51.7573 98.872 32.0542 82.3985 24.936 75.0493L76.3294 24.6133C83.7323 31.6761 100.189 47.806 106.795 55.8233C106.818 55.8515 106.841 55.88 106.864 55.9088Z" fill="url(#paint4_linear_966_30795)"/>
      <path d="M92.2736 28.3774L84.3013 23.5098L85.4402 22.0781L92.8431 25.5141L92.2736 28.3774Z" fill="#FBFFFF"/>
      <ellipse cx="50.1344" cy="49.5661" rx="36.1602" ry="36.3639" fill="url(#paint5_radial_966_30795)"/>
      <ellipse cx="85.4403" cy="80.4896" rx="23.0628" ry="23.1928" fill="url(#paint6_radial_966_30795)"/>
      <path d="M90.281 8.90723L79.7461 10.9115L81.4545 12.6295L89.9962 11.7705L90.281 8.90723Z" fill="#FDD470"/>
      <path d="M47.2423 59.1833C44.97 59.6622 42.8586 59.6628 40.9081 59.1848C38.9684 58.7046 37.3283 57.825 35.9879 56.5459C34.6475 55.2668 33.7616 53.664 33.3302 51.7374L40.0828 50.314C40.4191 51.5995 41.1668 52.5419 42.3261 53.141C43.4853 53.7402 44.7509 53.8951 46.1229 53.6059C47.2162 53.3755 48.1315 52.9261 48.8687 52.2577C49.6145 51.5762 50.1343 50.7428 50.4281 49.7576C50.7305 48.7592 50.7653 47.6803 50.5327 46.5211C50.2956 45.3401 49.8406 44.3531 49.1675 43.5603C48.5052 42.7652 47.6948 42.2065 46.7364 41.8842C45.7779 41.5619 44.7456 41.5117 43.6394 41.7334C42.6747 41.9368 41.7775 42.3368 40.9476 42.9335C40.1285 43.5279 39.5373 44.2281 39.174 45.0342L32.7569 45.2072L30.7606 27.1622L50.8899 22.9191L52.0554 28.7261L37.6658 31.7593L38.5027 40.3541L38.6957 40.3134C39.1077 39.3603 39.8429 38.4872 40.9015 37.6941C41.9601 36.9011 43.2128 36.352 44.6598 36.047C46.6428 35.629 48.5068 35.7319 50.2519 36.3558C51.9971 36.9796 53.4867 38.0277 54.7206 39.5002C55.9524 40.9617 56.7792 42.7708 57.2009 44.9274C57.6659 47.1889 57.5553 49.3096 56.869 51.2894C56.1912 53.2561 55.0262 54.9379 53.3738 56.3349C51.73 57.7187 49.6861 58.6681 47.2423 59.1833Z" fill="url(#paint7_linear_966_30795)"/>
      <path d="M73.4954 81.0298L74.5096 78.5854L82.3835 75.6803C83.0615 75.4129 83.6422 75.1598 84.1255 74.921C84.6139 74.6845 85.0186 74.4219 85.3395 74.1335C85.6626 73.8398 85.912 73.4814 86.0875 73.0583C86.2826 72.5882 86.344 72.1388 86.272 71.7099C86.2021 71.2758 86.0207 70.89 85.7277 70.5525C85.437 70.2097 85.0579 69.9402 84.5905 69.7441C84.1022 69.5392 83.6352 69.4597 83.1892 69.5056C82.7433 69.5516 82.3447 69.7185 81.9935 70.0066C81.6423 70.2946 81.3604 70.6945 81.1481 71.2064L77.9461 69.8627C78.3817 68.8129 78.9962 68.0006 79.7896 67.4259C80.583 66.8513 81.4933 66.5311 82.5207 66.4655C83.548 66.3998 84.6174 66.6002 85.7289 67.0666C86.8715 67.5461 87.7816 68.1672 88.4592 68.9298C89.1441 69.6894 89.5744 70.5262 89.7501 71.4401C89.9259 72.354 89.8219 73.2731 89.4383 74.1976C89.1869 74.8035 88.8193 75.3514 88.3355 75.8414C87.8569 76.3335 87.1668 76.8136 86.2652 77.2815C85.3658 77.7443 84.1601 78.2472 82.6481 78.7901L79.3661 80.1082L79.3206 80.2179L86.0207 83.0294L84.8699 85.8028L73.4954 81.0298Z" fill="url(#paint8_linear_966_30795)"/>
      <path d="M9.70316 59.5875C8.94389 59.2057 7.36841 58.3849 7.14062 58.1559L11.6962 57.2969L12.5504 58.1559L9.70316 59.5875Z" fill="white"/>
      <path d="M23.9391 28.3777L23.085 27.8051L24.7933 18.0698L27.9253 21.7921L23.9391 28.3777Z" fill="#FDD470"/>
      <path d="M38.7451 70.7548L34.1895 70.4685L39.5993 68.7505L38.7451 70.7548Z" fill="#FDD470"/>
      <path d="M111.066 72.7588L103.378 76.7674L103.663 77.3401L111.066 75.9084V72.7588Z" fill="white"/>
      <path d="M97.3985 52.4297L89.7109 56.4383L97.3985 55.5793V52.4297Z" fill="#FDD470"/>
      <mask id="mask1_966_30795" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="8" y="8" width="100" height="99">
        <rect width="98.8148" height="97.6812" rx="34.0886" transform="matrix(0.999896 0.0144454 -0.0142841 0.999898 9.39551 7.90137)" fill="url(#paint9_linear_966_30795)"/>
      </mask>
      <g mask="url(#mask1_966_30795)">
        <ellipse cx="30.5726" cy="96.9927" rx="9.70051" ry="9.75516" fill="url(#paint10_radial_966_30795)"/>
        <ellipse cx="50.829" cy="104.166" rx="7.13273" ry="7.17291" fill="url(#paint11_radial_966_30795)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_966_30795" x1="3.96682" y1="17.5431" x2="97.3105" y2="90.336" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBF4D"/>
          <stop offset="0.301202" stopColor="#FF996C"/>
          <stop offset="0.671173" stopColor="#FD87A1"/>
          <stop offset="1" stopColor="#B965EF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_966_30795" x1="1.14189" y1="25.5745" x2="98.9609" y2="64.7324" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC4348"/>
          <stop offset="1" stopColor="#FF6750"/>
        </linearGradient>
        <linearGradient id="paint2_linear_966_30795" x1="34.1943" y1="97.1537" x2="43.0736" y2="106.553" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.6"/>
          <stop offset="1" stopOpacity="0.15"/>
        </linearGradient>
        <linearGradient id="paint3_linear_966_30795" x1="55.5425" y1="102.69" x2="60.2417" y2="108.281" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.6"/>
          <stop offset="1" stopOpacity="0.15"/>
        </linearGradient>
        <linearGradient id="paint4_linear_966_30795" x1="41.1427" y1="41.7748" x2="106.902" y2="101.828" gradientUnits="userSpaceOnUse">
          <stop offset="0.288863" stopOpacity="0.6"/>
          <stop offset="0.782491" stopOpacity="0.15"/>
        </linearGradient>
        <radialGradient id="paint5_radial_966_30795" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.0476 38.3573) rotate(37.1436) scale(55.0075 55.0921)">
          <stop offset="0.545283" stopColor="white"/>
          <stop offset="1" stopColor="#BCA8F0"/>
        </radialGradient>
        <radialGradient id="paint6_radial_966_30795" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(75.1802 73.3407) rotate(37.1436) scale(35.0835 35.1374)">
          <stop offset="0.545283" stopColor="white"/>
          <stop offset="1" stopColor="#BCA8F0"/>
        </radialGradient>
        <linearGradient id="paint7_linear_966_30795" x1="36.0455" y1="25.4165" x2="50.9923" y2="60.0016" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7951E0"/>
          <stop offset="1" stopColor="#5827D8"/>
        </linearGradient>
        <linearGradient id="paint8_linear_966_30795" x1="81.6135" y1="67.0779" x2="83.291" y2="84.7317" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7951E0"/>
          <stop offset="1" stopColor="#5827D8"/>
        </linearGradient>
        <linearGradient id="paint9_linear_966_30795" x1="1.14423" y1="25.6269" x2="99.1635" y2="64.8649" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC4348"/>
          <stop offset="1" stopColor="#FF6750"/>
        </linearGradient>
        <radialGradient id="paint10_radial_966_30795" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26.2571 93.9858) rotate(37.1436) scale(14.7566 14.7792)">
          <stop offset="0.545283" stopColor="#FDD470"/>
          <stop offset="1" stopColor="#D39703"/>
        </radialGradient>
        <radialGradient id="paint11_radial_966_30795" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(47.6559 101.955) rotate(37.1436) scale(10.8504 10.8671)">
          <stop offset="0.545283" stopColor="#FDD470"/>
          <stop offset="1" stopColor="#D39703"/>
        </radialGradient>
      </defs>
    </svg>
  )
}
export default SVGSession;