import { IDraw } from 'src/models/lottery/draw.model';
import { ACTIONS } from '../actions/ticket.actions';
import { IBetTicket } from 'src/models/ticketing/ticket.model';

export interface ITicketReducer {
  error: boolean;
  isFetching: boolean;
  errorMessage: string;
  betList: IBetTicket[];
  drawResultList: IDraw[];
  isDrawResultsFetching: boolean;
  pagination: any;
}

const initialState = <ITicketReducer>{
  error: false,
  isFetching: true,
  isDrawResultsFetching: false,
  errorMessage: '',
  betList: [],
  drawResultList: [],
  pagination: {}
}


export default function ticketsReducer(state = initialState, action): ITicketReducer {
  switch (action.type) {
    //------------------
    // TICKETS
    case ACTIONS.TICKETS_ERROR: {
      return {
        ...state,
        error: true,
        isFetching: false,
        errorMessage: action.payload?.message,
      }
    }
    case ACTIONS.TICKETS_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.TICKETS_FETCHING_DONE: {
      return {
        ...state,
        isFetching: false,
        betList: action.payload.betList,
        pagination: action.payload.pagination
      }
    }
    //------------------
    // DRAW RESULTS
    case ACTIONS.TICKETS_DRAWS_RESULTS_FETCHING: {
      return {
        ...state,
        isDrawResultsFetching: true
      }
    }
    case ACTIONS.TICKETS_DRAWS_RESULTS_FETCHING_DONE: {
      return {
        ...state,
        isDrawResultsFetching: false,
      }
    }
    case ACTIONS.TICKETS_DRAW_RESULTS: {
      return {
        ...state,
        isDrawResultsFetching: false,
        drawResultList: action.payload
      }
    }
    default:
      return state
  }
}