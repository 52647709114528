import * as React from "react";

const SVGEmptyBasket = ({ fill, width, height }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="148" height="148" viewBox="0 0 148 148" fill="none">
      <rect width="125.695" height="128.066" rx="34.0886" transform="matrix(0.985058 0.172222 -0.163038 0.98662 20.8794 0)" fill="#BCA8F0"/>
      <rect width="123.009" height="124.373" rx="34.0886" transform="matrix(0.999891 0.0147752 -0.0139653 0.999902 10.9575 10.0811)" fill="url(#paint0_linear_2198_86385)"/>
      <mask id="mask0_2198_86385" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="9" y="10" width="125" height="126">
        <rect width="123.009" height="124.373" rx="34.0886" transform="matrix(0.999891 0.0147752 -0.0139653 0.999902 10.9062 10.0811)" fill="url(#paint1_linear_2198_86385)"/>
      </mask>
      <g mask="url(#mask0_2198_86385)">
        <path d="M39.6242 133.678L21.4224 84.1453L87.3525 19.0969L141.145 48.9356L146.979 105.629L147.563 131.887L60.045 149.79L39.6242 133.678Z" fill="url(#paint2_linear_2198_86385)"/>
      </g>
      <path d="M25.7998 64.5079C24.8663 64.9853 21.5212 64.309 19.9653 63.9111L19.3818 60.9272L21.1322 57.9434L27.5502 62.1208C27.3557 62.7176 26.7333 64.0305 25.7998 64.5079Z" fill="#D39703"/>
      <path d="M3.62801 59.7339C2.69448 58.7791 7.14099 55.4999 8.69686 54.3064L15.2971 57.9436C16.853 59.535 19.9647 63.0758 19.9647 64.5081C19.9647 66.2984 17.0474 68.6855 15.8805 69.2823C14.7136 69.8791 12.3798 63.3145 11.2129 62.121C10.046 60.9274 4.79491 60.9274 3.62801 59.7339Z" fill="#D39703"/>
      <path d="M5.37891 50.1855C5.37891 49.2307 7.9072 47.3434 8.87962 46.7466L13.5472 53.1694L8.87962 54.9597C7.71272 53.7661 5.37891 51.1403 5.37891 50.1855Z" fill="#D39703"/>
      <path d="M7.12858 56.75C6.19506 56.2726 9.07343 53.3683 10.6293 51.9758C10.4348 51.578 9.8125 50.4242 8.87898 48.992C7.94545 47.5597 8.49001 46.8038 8.87898 46.6048C10.746 46.1274 14.3245 48.793 15.8804 50.1855C17.4363 49.7876 20.8981 49.1113 22.2984 49.5887C24.0487 50.1855 22.2984 55.5565 22.2984 56.1532C27.5495 60.9274 28.1329 62.121 27.5495 62.121C27.0827 62.121 23.0763 61.3253 21.1315 60.9274C20.937 62.3199 20.198 65.2242 18.7977 65.7016C17.3974 66.179 14.7135 61.5242 13.5466 59.1371C11.7962 58.5403 8.06211 57.2274 7.12858 56.75Z" fill="#FDD470"/>
      <path d="M90.2662 121.67C93.5799 116.607 87.9155 111.196 84.669 109.123C84.3461 108.001 85.2518 108.27 85.745 108.545C88.4784 109.756 90.9192 108.682 91.7979 107.994C98.2818 112.396 100.274 120.183 98.0947 122.109C96.3517 123.65 92.1495 122.458 90.2662 121.67Z" fill="#FDD470"/>
      <path d="M45.5413 13.5351C40.0955 20.953 48.2905 29.6667 53.0687 33.0963C53.4715 34.8314 52.1139 34.344 51.3848 33.8834C47.3194 31.8088 43.5185 33.2328 42.1263 34.2041C32.6037 26.955 30.1883 14.9415 33.6579 12.1978C36.4336 10.0028 42.7367 12.1748 45.5413 13.5351Z" fill="#FDD470"/>
      <g filter="url(#filter0_d_2198_86385)">
        <path d="M82.689 19.1287L20.1367 82.376C19.2664 83.7075 20.4994 85.5199 21.2246 86.2596L33.1911 98.4652C34.0977 99.02 36.3459 99.6858 38.0865 97.9104C40.2623 95.6912 43.5259 94.5816 47.3334 98.4652C51.1409 102.349 49.5091 105.678 47.3334 107.897C45.5928 109.672 46.6081 112.335 47.3334 113.445L57.6681 123.986C60.279 126.649 63.4701 124.726 64.7392 123.431L125.116 61.8483C127.292 59.6291 125.66 57.225 124.572 56.3003L113.693 45.2043C111.953 43.4289 110.067 44.6295 109.342 45.4516C107.71 47.116 103.467 49.4462 99.551 45.4516C94.6556 40.4584 97.9192 36.8823 99.551 34.6631C100.856 32.8877 99.3697 30.5946 98.4631 29.6699C96.1061 27.0808 90.7393 21.3479 88.1284 19.1287C85.5175 16.9095 83.4143 18.204 82.689 19.1287Z" fill="url(#paint3_linear_2198_86385)"/>
      </g>
      <path d="M81.7208 24.4435L25.4237 81.2861C24.6404 82.4827 25.7501 84.1116 26.4028 84.7764L34.3444 92.8934C35.1603 93.392 37.1838 93.9903 38.7503 92.3948C40.7084 90.4003 46.4739 92.2557 49.9007 95.746C53.3275 99.2363 54.3044 104.695 52.3463 106.689C50.7798 108.285 51.6936 110.678 52.3463 111.675C54.4676 113.836 56.8521 116.289 59.2019 118.682C61.5517 121.076 64.4237 119.347 65.566 118.184L119.905 62.8372C121.863 60.8427 120.394 58.682 119.415 57.851C116.804 55.1917 113.735 51.2673 112.169 49.6718C110.602 48.0762 110.537 49.1232 108.796 50.4426C107.328 51.9384 100.421 51.6909 96.8965 48.1009C92.4906 43.6133 93.5463 38.5015 95.0149 36.507C96.1898 34.9114 96.7333 34.7483 95.9174 33.9173C93.7961 31.5904 88.966 26.438 86.6162 24.4435C84.2664 22.4491 82.3735 23.6125 81.7208 24.4435Z" stroke="#5C2CD9"/>
      <rect width="6.55432" height="2.18217" rx="1.09108" transform="matrix(0.679925 0.733282 -0.71777 0.69628 76.7637 35.1301)" fill="#5C2CD9"/>
      <rect width="6.55432" height="2.18217" rx="1.09108" transform="matrix(0.679925 0.733282 -0.71777 0.69628 84.3193 42.8582)" fill="#5C2CD9"/>
      <rect width="6.55432" height="2.18217" rx="1.09108" transform="matrix(0.679925 0.733282 -0.71777 0.69628 90.7954 49.4824)" fill="#5C2CD9"/>
      <rect width="6.55432" height="2.18217" rx="1.09108" transform="matrix(0.679925 0.733282 -0.71777 0.69628 98.3511 57.21)" fill="#5C2CD9"/>
      <rect width="6.55432" height="2.18217" rx="1.09108" transform="matrix(0.679925 0.733282 -0.71777 0.69628 104.827 63.834)" fill="#5C2CD9"/>
      <path d="M144.21 81.0946C144.21 82.4071 142.606 84.3759 141.804 85.1962L132.982 81.9149L141.002 73.6265C142.071 75.814 144.21 79.782 144.21 81.0946Z" fill="#D39703"/>
      <path d="M125.764 109.806C124.481 109.806 122.556 105.978 121.754 104.064L130.576 91.7591L145.012 87.6575C149.504 92.9076 147.953 94.767 146.616 95.0404C143.676 96.1342 137.313 98.4858 135.388 99.1421C132.982 99.9624 127.368 109.806 125.764 109.806Z" fill="#D39703"/>
      <path d="M111.623 103.979C109.597 103.979 107.641 100.151 106.516 98.2369L117.533 95.7759L119.221 99.0572L121.754 103.159C119.221 103.432 113.649 103.979 111.623 103.979Z" fill="#D39703"/>
      <path d="M106.515 97.5014L115.337 89.2981C115.605 87.384 116.139 83.0636 116.139 81.0948C116.139 79.126 118.278 79.1807 119.347 79.4542L128.972 80.2745C130.576 78.9073 134.586 75.6806 137.794 73.7118C141.002 71.743 141.269 73.4384 141.002 74.5321L138.596 83.5558C139.665 84.1027 142.285 85.5246 144.21 86.8371C146.135 88.1496 145.012 89.5715 144.21 90.1184L132.18 93.3997C130.041 96.4076 125.443 102.752 124.159 104.064C122.876 105.377 122.021 104.611 121.753 104.064L118.545 99.142H108.921C105.713 99.142 105.981 98.0483 106.515 97.5014Z" fill="#FDD470"/>
      <path d="M30.9231 47.1452L35.5908 50.1291L34.4239 51.3226L29.7562 50.1291L30.9231 47.1452Z" fill="white"/>
      <path d="M107.355 19.6936L102.688 27.4517L103.271 32.2259L110.856 23.2742L107.355 19.6936Z" fill="white"/>
      <path d="M15.1699 91.3065L21.0044 90.113L22.7548 91.9033L16.9203 94.8871L15.1699 91.3065Z" fill="white"/>
      <defs>
        <filter id="filter0_d_2198_86385" x="7.8374" y="11.9033" width="130.351" height="131.419" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="6"/>
          <feGaussianBlur stdDeviation="6"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2198_86385"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2198_86385" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_2198_86385" x1="1.42438" y1="32.6294" x2="124.191" y2="80.6774" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C621E5"/>
          <stop offset="1" stopColor="#7D7CF9"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2198_86385" x1="1.42438" y1="32.6294" x2="124.191" y2="80.6774" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5827D8"/>
          <stop offset="1" stopColor="#9B7DE8"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2198_86385" x1="49.5319" y1="41.1776" x2="140.687" y2="126.517" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.6"/>
          <stop offset="0.732769" stopOpacity="0.15"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2198_86385" x1="62.6875" y1="41.6193" x2="102.268" y2="79.4364" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="#DACFF6"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SVGEmptyBasket;