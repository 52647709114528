import { Theme } from "@mui/material";
import commonStyle from "src/common/style/commonStyle";

export const makeStyle = (theme: Theme) => ({
  container: {
    height: '100%', //scroll
  },
  body: {
    flex: 1,
    paddingLeft: '11px',
    paddingRight: '11px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  //
  sessionButton: {
    backgroundColor: '#EDE1FE',
    marginBottom: '8px',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '48px',
    width: '100%',
    borderRadius: '32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "&:hover, &:active": {
      backgroundColor: '#EDE1FE',
    },
  },
  sessionButtonSelected: {
    backgroundColor: theme.palette.primary.main,
    "&:hover, &:active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  sessionButtonText: {
    color: '#000',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'none'
  },
  sessionButtonTextName: {
    color: '#6A3DD3',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'none'
  },
  sessionButtonTextSelected: {
    color: '#fff',
  },
  sessionButtonText_Date: {
    color: '272727'
  },
  sessionButtonText_Name: {
    color: '6A3DD3',
    fontSize: '12px'
  },
  sessionButtonText_ClosingDate: {
  },
  //
  validateContainer: {
    backgroundColor: theme.palette.background.default,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  validateRow: {
    margin: '16px',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center'
  },
  validateButton: {
    backgroundColor: '#272727',
    height: '48px',
    width: '100%',
    borderRadius: '16px',
    "&:hover, &:active": {
      backgroundColor: "#272727"
    },
    [theme.breakpoints.up('lg')]: {
      width: '298px'
    },
  },
  validateButtonDisabled: {
    backgroundColor: '#F9F9F9',
    "&:hover, &:active": {
      backgroundColor: "#F9F9F9"
    },
  },
  validateButtonText: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 700
  },
  validateButtonTextDisabled: {
    color: 'rgba(39, 39, 39, 0.15)',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 700
  },
  stepBox: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '59px',
      marginRight: '59px'
    },
    height: '56px',
    borderRadius: '16px',
    background: '#FFF',
    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.08)',
    marginBottom: '28px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '24px',
    height: '96px',
    background: theme.palette.sticker.main,
    marginBottom: '32px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '59px',
      marginRight: '59px'
    },
  },
  topBoxSubContainer: {
    marginLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  topBoxTitleText: {
    color: "#FFF",
    fontSize: '16px',
    fontWeight: 800
  },
  topBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  gameName: {
    marginTop: '-10px',
    marginRight: "5px",
    color: '#FFF',
    fontSize: '32px',
    fontWeight: 800,
    whiteSpace: 'nowrap',
  },
  currencyId: {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 800
  },
  stepTitle: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#272727',
    marginLeft: '10px'
  },
  sessionDate: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#272727',
    textAlign: 'left',
    marginTop: '16px',
    marginBottom: '16px'
  },
  sessionContainer: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '59px',
      paddingRight: '50px',
      overflowY: 'auto',
    },
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '80px', // for the validate footer
    },
    ...commonStyle.slimScrollBar(theme),
  }
})