import { Box, Tabs, Tab, useTheme, Typography, Button, CircularProgress } from "@mui/material";
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { makeStyle } from './myBets.style';
import { RootState } from "src/store/root.reducer";
import SVGFilterTickets from "src/assets/icons/SVGFilterTickets";
import SVGEmptyBasket from "src/assets/icons/SVGEmptyBasket";
// Actions
import * as ticketActions from 'src/store/actions/ticket.actions';
import { loadTaxes } from "src/store/actions/taxes.actions";
// Component
import BetItem from "src/component/ticketing/myBets/betItem.comp";
import dayjs from "dayjs";

const MyBetsPage = () => {

  const TAB_PENDING = 0, TAB_PAST = 1;

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>()
  const container = useRef<any>();

  //====================================== States

  const betList = useSelector((state: RootState) => state.tickets.betList);
  const isBetListFetching = useSelector((state: RootState) => state.tickets.isFetching);
  const pagination = useSelector((state: RootState) => state.tickets.pagination);
  const periodInDaysConfig = useSelector((state: RootState) => state.app.config.betting.periodInDays) || 3;

  const [currentTab, setCurrentTab] = useState(TAB_PENDING);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [size, setSize] = useState<number>(10)
  const tabList = ['MyBets.pending', 'MyBets.past'];

  //====================================== Effects

  useEffect(() => {
    dispatch(loadTaxes());
  }, [])

  useEffect(() => {
    setShowMoreButton(pagination.totalElements > pagination.numberOfElements)
  }, [pagination])

  useEffect(() => {
    onGetPlayerBet();
  }, [currentTab, size])

  const onGetPlayerBet = () => {
    const waitingBets = currentTab === TAB_PENDING;
    const periodInDays = waitingBets ? 7 : periodInDaysConfig;
    const createdAfter = dayjs().add(-periodInDays, 'day').toISOString();
    const sort = `drawingDate,${waitingBets ? "asc" : "desc"}`;
    dispatch(ticketActions.getPlayerBets(waitingBets, createdAfter, size, sort));
  }

  //====================================== Functions

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  //====================================== Render

  return (
    <Box sx={style.betsContainer} ref={container}>
      <Tabs sx={style.tabs} value={currentTab} onChange={handleTabChange}>
        {tabList.map((tabKey, index) => (
          <Tab
            key={index}
            sx={[style.tab, currentTab === index && style.tabSelected]}
            disableRipple
            label={<Typography sx={[style.tabText, currentTab === index && style.tabTextSelected]}>{t(tabKey)}</Typography>}
          />
        ))}
      </Tabs>

      {currentTab === TAB_PAST && betList.length !== 0 && 
        <Box sx={style.boxFilter}>
          <SVGFilterTickets />
          <Typography sx={style.boxFilterTxt}>{t('MyBets.filter')}</Typography>
        </Box>
      }

      <Box sx={style.listContainer}>
        {isBetListFetching ? 
          <Box sx={style.loaderBox}>
            <CircularProgress sx={{color: theme.palette.primary.main}} size={24}/>
          </Box> :
          <>
            {betList.length === 0 && 
              <Box sx={style.ticketsEmpty}>
                <SVGEmptyBasket />
                <Typography sx={style.ticketsEmptyTxt}>{t('Ticket.empty_tickets')}</Typography>
              </Box>
            }
            {betList?.map((bet, index) => (
                <BetItem key={index} item={bet} index={index} />
            ))}
            {showMoreButton &&
              <Button
                sx={style.showMoreButton}
                disableRipple
                onClick={() => setSize(size + 10)}
              >
                <Typography sx={style.showMoreButtonText}>
                  {t("Ticket.show_more_results")}
                </Typography>
              </Button>
            }
          </>  
        }
      </Box>
    </Box>
  )
}

export default MyBetsPage;