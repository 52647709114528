import { AppEntryParams } from "src/models/core/app.model";
import { RootState } from "../root.reducer";
import * as service from 'src/store/services/appConfig.service';

// Definition of the actions name
export const ACTIONS = {
  APP_ENTRY_PARAMS: 'APP_ENTRY_PARAMS',
  APP_CONFIG_FETCHING: 'APP_CONFIG_FETCHING',
  APP_CONFIG_FAILED: 'APP_CONFIG_FAILED',
  APP_CONFIG_DATA: 'APP_CONFIG_DATA',
}

// Dispatchers
export const set_app_entry_params = (data: AppEntryParams) => {
  return { type: ACTIONS.APP_ENTRY_PARAMS, payload: data };
}

export const set_app_config = (data) => {
  return { type: ACTIONS.APP_CONFIG_DATA, payload: data };
}

export const set_state_fetching = () => {
  return { type: ACTIONS.APP_CONFIG_FETCHING };
}

export const set_state_failed = (err: any) => {
  return { type: ACTIONS.APP_CONFIG_FAILED, payload: err };
}

// Actions

export const exitApp = () => {
  return (_, getState: () => RootState) => {
    const exitUrl = getState().app.entryParams.exitUrl;
    if (exitUrl) {
      if (window.top)
        window.top.location.replace(exitUrl);
      else
        window.location.replace(exitUrl);
    }
  }
}

export const loadAppConfig = () => {
  return async dispatch => {
  try {
      dispatch(set_state_fetching());
      let r = await service.loadAppConfig();
      if (!r) {
        dispatch(set_state_failed({message: "config file error"}));
      } else {
        dispatch(set_app_config(r))
      }
    } catch (err) {
      dispatch(set_state_failed(err));
    }
  }
}