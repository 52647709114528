import * as React from "react";

const SVGBalance = ({ fill, width, height }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="116" height="116" viewBox="0 0 116 116" fill="none">
      <rect width="100.701" height="100.438" rx="34.0886" transform="matrix(0.985704 0.168488 -0.166658 0.986015 16.7388 0)" fill="#FFE3B8"/>
      <rect width="98.6129" height="97.4816" rx="34.0886" transform="matrix(0.999896 0.0144454 -0.0142841 0.999898 8.78418 7.90137)" fill="url(#paint0_linear_1131_18999)"/>
      <mask id="mask0_1131_18999" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="7" y="8" width="100" height="99">
        <rect width="98.6129" height="97.4816" rx="34.0886" transform="matrix(0.999896 0.0144454 -0.0142841 0.999898 8.39258 7.90137)" fill="url(#paint1_linear_1131_18999)"/>
      </mask>
      <g mask="url(#mask0_1131_18999)">
        <path d="M111.399 81.8547L104.291 63.145L90.3505 67.8224L44.044 81.387L41.6133 86.9999L49.5649 113.193L92.2215 108.984L111.399 81.8547Z" fill="url(#paint2_linear_1131_18999)"/>
      </g>
      <path d="M43.4347 93.5488L33.9139 77.8121L29 64.8524C36.678 58.3726 42.8204 50.6585 67.083 50.6585C86.4931 50.6585 93.3931 39.9617 94.4168 34.6133L110.387 61.1497C108.851 66.3953 103.938 79.6634 75.6824 77.5035C53.0783 75.7755 44.7655 87.4804 43.4347 93.5488Z" fill="url(#paint3_linear_1131_18999)"/>
      <path d="M38.7451 70.7543L34.1895 70.468L39.5993 68.75L38.7451 70.7543Z" fill="#FDD470"/>
      <path d="M19.8331 80.8705L2.80664 52.7137C21.1686 54.3897 24.8409 54.3898 41.5336 31.261C54.8877 12.7579 70.0224 13.7188 75.9204 16.5122L92.6131 45.3393C72.5819 39.3057 61.5648 56.0657 49.8799 71.8201C40.532 84.4237 25.9538 83.1052 19.8331 80.8705Z" fill="url(#paint4_linear_1131_18999)"/>
      <ellipse cx="91.9425" cy="87.7696" rx="9.93062" ry="6.06492" transform="rotate(-139.527 91.9425 87.7696)" fill="url(#paint5_linear_1131_18999)"/>
      <ellipse cx="30.3476" cy="28.5956" rx="8.57228" ry="8.84092" transform="rotate(26.8062 30.3476 28.5956)" fill="url(#paint6_linear_1131_18999)"/>
      <ellipse cx="51.5377" cy="100.925" rx="8.60133" ry="12.5645" transform="rotate(46.9837 51.5377 100.925)" fill="url(#paint7_linear_1131_18999)"/>
      <ellipse cx="28.0141" cy="26.598" rx="8.57228" ry="8.84092" transform="rotate(26.8062 28.0141 26.598)" fill="#D39703"/>
      <ellipse cx="26.6064" cy="25.789" rx="8.57228" ry="8.84092" transform="rotate(26.8062 26.6064 25.789)" fill="#FDD470"/>
      <ellipse cx="92.9519" cy="85.617" rx="10.028" ry="6.06492" transform="rotate(-139.527 92.9519 85.617)" fill="#D39703"/>
      <ellipse cx="93.4693" cy="84.9629" rx="9.93062" ry="6.06492" transform="rotate(-139.527 93.4693 84.9629)" fill="#FDD470"/>
      <ellipse cx="48.1046" cy="101.139" rx="8.60133" ry="12.5645" transform="rotate(46.9837 48.1046 101.139)" fill="#D39703"/>
      <ellipse cx="46.8609" cy="99.9894" rx="8.60133" ry="12.5645" transform="rotate(46.9837 46.8609 99.9894)" fill="#FDD470"/>
      <defs>
        <linearGradient id="paint0_linear_1131_18999" x1="3.96682" y1="17.5431" x2="97.3105" y2="90.336" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBF4D"/>
          <stop offset="0.301202" stopColor="#FF996C"/>
          <stop offset="0.671173" stopColor="#FD87A1"/>
          <stop offset="1" stopColor="#B965EF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1131_18999" x1="1.14189" y1="25.5745" x2="98.9609" y2="64.7324" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5827D8"/>
          <stop offset="1" stopColor="#9B7DE8"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1131_18999" x1="55.4179" y1="73.6658" x2="94.7762" y2="123.607" gradientUnits="userSpaceOnUse">
          <stop offset="0.288863" stopOpacity="0.4"/>
          <stop offset="0.782491" stopOpacity="0.05"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1131_18999" x1="61.7919" y1="47.625" x2="82.6635" y2="75.4403" gradientUnits="userSpaceOnUse">
          <stop stopColor="#987AE6"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1131_18999" x1="38.9908" y1="29.8383" x2="63.0655" y2="60.816" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="#DACFF6"/>
        </linearGradient>
        <linearGradient id="paint5_linear_1131_18999" x1="85.9407" y1="84.2545" x2="95.0198" y2="97.7826" gradientUnits="userSpaceOnUse">
          <stop offset="0.288863" stopOpacity="0.6"/>
          <stop offset="0.782491" stopOpacity="0.15"/>
        </linearGradient>
        <linearGradient id="paint6_linear_1131_18999" x1="25.1668" y1="23.4716" x2="39.3567" y2="35.9922" gradientUnits="userSpaceOnUse">
          <stop offset="0.288863" stopOpacity="0.6"/>
          <stop offset="0.782491" stopOpacity="0.15"/>
        </linearGradient>
        <linearGradient id="paint7_linear_1131_18999" x1="46.3393" y1="93.6429" x2="64.5824" y2="105.008" gradientUnits="userSpaceOnUse">
          <stop offset="0.288863" stopOpacity="0.6"/>
          <stop offset="0.782491" stopOpacity="0.15"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
export default SVGBalance;