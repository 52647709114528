import { Theme } from "@mui/material";
import commonStyle from "src/common/style/commonStyle";

export const makeStyle = (theme: Theme) => ({
  container: {
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  content: {
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: "calc(100%)",
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: '139px',
    }
  },
  tabButtonBar: {
    marginBottom: '24px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '8px',
      paddingLeft: '139px',
      paddingRight: '139px',
    }
  },
  tabButton: {
    height: '48px',
    borderRadius: '16px',
    width: '100%',
  },
  tabButtonSelected: {
    backgroundColor: theme.palette.primary.main,
    "&:hover, &:active": { backgroundColor: theme.palette.primary.main },
  },
  tabButtonText: {
    color: '#BBB',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: 'normal',
    textTransform: 'none',
  },
  tabButtonTextSelected: {
    color: '#FFF',
  },
  betTypeCollapseBox: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '139px',
      paddingRight: '139px'
    }
  },
  betTypeCollapse: {
    backgroundColor: "#1D1D1D",
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 24px',
    marginBottom: '12px',
    borderRadius: '24px',
    width: '100%',
    height: '48px',
    "&:hover, &:active": {
      backgroundColor: "#1D1D1D",
    },
  },
  betTypeCollapseOpen: {
    backgroundColor: "#1D1D1D",
    "&:hover, &:active": {
      backgroundColor: "#1D1D1D",
    },
  },
  betTypeCollapseText: {
    overflow: 'hidden',
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'none'
  },
  betTypeButtonBox: {
    display: 'flex',
    marginBottom: '10px',
    width: '100%'
  },
  betTypeButton: {
    height: '48px',
    width: '100%',
    marginBottom: '8px',
    backgroundColor: '#FFF',
    borderRadius: '12px',
    boxShadow: '0px 12px 16px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(0, 0, 0, 0.03)',
    "&:hover, &:active": {
      backgroundColor: '#FFF',
    },
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  betTypeButtonText: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'none',
  },
  groupArrowIcon: {
    width: '24px',
    height: '24px',
  },
  groupArrowIconOpen: {
    transform: 'rotate(180deg)',
  },
})