export const translationFR = {
  "back": "Retour",
  "balance": "Solde",
  "cancel": "Annuler",
  "clear": "Effacer",
  "combinations": "Combinaisons",
  "confirm": "Confirmer",
  "draw": "Tirage",
  "draws": "Tirages",
  "gameMode": "Mode de jeu",
  "number": "Numéro",
  "numbers": "Numéros",
  "others": "Autres",
  "rules": "Règles",
  "stake": "Mise",
  "stakes": "Mises",
  "tax": "Taxe",
  "tickets": "Tickets",
  "ticket": "Ticket",
  "understood": "J'ai compris",
  "wallet": "Solde",

  "Basket": {
    "basket": "Panier",
    "add_bet": "Ajouter un pari",
    "insufficient_credits": "Votre mise de {{N}} est trop élevée par rapport à votre balance",
    "remove_item_confirm": "Confirmer la suppression du pari ?",
    "total_of": "Total du",
    "validate": "Valider mon panier",
    "empty_basket": "Panier vide,\nque diriez vous de parier?",
  },

  "Booking": {
    "bet_creation1": "Création",
    "bet_creation2": " de votre pari",
  },

  "Bet": {
    "active_permutation": "Permutation active",
    "details": "Détails du pari",
    "max_gain_gross": "Gain max brut",
    "max_gain_net": "Gain max net",
    "max_gain": "Gain max",
  },

  "Exit": {
    "leaveTheGame": "Vous vous apprêtez à quitter l'application {{game}}.",
    "leave": "Quitter le jeu",
  },

  "Error": {
    "back_to_draws": "Retour aux tirages",
    "draw_bet_closed": "Le tirage ne peut plus être joué, veuillez le supprimer",
    "not_enough_draws": "Le nombre de tirages choisi dépasse le nombre de tirages disponibles",
    "pageReload": "Recharger la page",
    "reloadGame": "veuillez réouvrir le jeu",
    "seeMyBets": "Voir mes paris",
    "session_list_empty_1": "Aucun tirage",
    "session_list_empty_2": "de disponible pour le moment",
    "session_expired_1": "Votre tirage",
    "session_expired_2": "n'est plus disponible à la sélection",
    "technicalError": "Erreur technique",
    "contact_support": "veuillez contacter le support",
    "booking_error_refund1": "Une erreur ",
    "booking_error_refund2": "est intervenue pendant la création du ticket",
    "booking_error_refund_action": "Revenir aux sessions de jeu",
    "booking_error_refund": "Remboursement effectué",
  },

  "GameRules": {
    "game_rules": "Règles du jeu",
    "max_gain": "Gain max",
    "rules": "Règles",
    "what_is_it": "C'est quoi ?",
    "enter_an_amount": "Entrez un montant",
    "potential_win_default": "Gains potentiels pour la mise par défaut."
  },

  "Grid": {
    "max_selections_reached": "Vous n'avez pas le droit de sélectionner plus de {{max}} numéros",
    "permutate": "Permuter",
    "permutation_enabled": "Permutation active",
    "permutation_entering": "Vous vous apprêtez à entrer en permutation",
    "permutation_help_1": "Jouer en formule <[Permutation]> consiste à choisir plus de numéros que le nombre de numéros définis pour le type de pari (Simple).",
    "permutation_help_2": "Si un joueur choisit <[X]> numéros en permutation, alors il joue l'équivalent de <[N]> prises de jeux simples correspondant à <[N]> combinaisons simples.",
    "permutation_mincount_1": "Activer la permutation à partir de",
    "permutation_mincount_2": "numéros",
    "select_your_numbers_1": "Sélectionnez vos ",
    "select_your_numbers_2": "numéros",
    "validate_selection": "Valider ma sélection",
    "delete_grid": "Effacer la grille"
  },

  "MyBets": {
    "pending": "En cours",
    "past": "Passés",
    "filter": "Filtre",
  },

  "Session": {
    "welcome": "Bienvenue sur le jeu ",
    "select_game_session": "Choisir une session de jeu",
    "select_game_sessions": "Choisir des sessions de jeu",
    "validate_selection": "Valider ma sélection",
    "today": "Aujourd'hui",
    "tomorrow": "Demain"
  },

  "Stake": {
    "error_amount_too_high": "Montant trop élevé, vous ne pouvez saisir plus de {{N}} {{currency}}.",
    "error_amount_too_low": "Montant trop bas, vous ne pouvez saisir moins de {{N}} {{currency}}.",
    "error_amount_staggering": "Seul les intervalles de {{N}} sont valides, veuillez ressaisir un montant correct.",
    "error_total_price_exceeded": "Le prix total du panier est trop élevé (max. {{N}}).",
    "title1": "Montant de la ",
    "title2": "mise",
    "personalized_amount": "Montant personnalisé",
    "unit_price": "Prix unitaire",
    "unit_ticket_price": "Prix unitaire du ticket",
    "reset_grid": "Vous vous apprêtez à effacer votre grille, êtes-vous certain ?",
    "ticket_price": "Prix du ticket",
    "max_price": "Gain max",
    "validate_amount": "Valider le montant",
    "validate_stake": "Valider la mise",
    "max_draws": "Tirages maximum"
  },

  "Ticket": {
    "draw_results": "Résultats du tirage",
    "wait": "Tirages à venir",
    "won": "Gagnant",
    "canceled": "Annulé",
    "lost": "Perdant",
    "totalCost": "Coût total",
    "refTicket": "Réf ticket",
    "contact_support": "Contacter le support",
    "win_payment_blocked_error": "Nous rencontrons une erreur technique pour régler votre gain",
    "canceled_payment_blocked_error": "Nous avons rencontré une erreur technique lors du paiement de vos remboursements",
    "empty_tickets": "Vous n'avez pas de pari ? Commençons ensemble à en créer",
    "email": "Mail",
    "n_transaction": "N° transaction",
    "bet": "Pari",
    "show_more_results": "Afficher plus de résultats"
  }
}