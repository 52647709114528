import dayjs from 'dayjs';
import { RootStateFn } from '../root.reducer';
import { EBasketStatus, IBasketItem } from 'src/models/lottery/basket.model';
import { IDraw } from 'src/models/lottery/draw.model';

//=========================================================

export const ACTIONS = {
  BASKET_CLEAR: 'BASKET_CLEAR',
  BASKET_SET_ITEM_LIST: 'BASKET_SET_ITEM_LIST',
  BASKET_STATUS: 'BASKET_STATUS',
  BASKET_SET_GAME_INFO: 'BASKET_SET_GAME_INFO',
  BASKET_TOTAL_STAKE: 'BASKET_TOTALSTAKE',
}

//=========================================================
// SETTERS
export const set_item_list = (list) => {
  return { type: ACTIONS.BASKET_SET_ITEM_LIST, payload: sortListByDrawingDate(list) };
}
export const set_status = (status: EBasketStatus) => {
  return { type: ACTIONS.BASKET_STATUS, payload: status };
}
export const set_total_stake = (totalStake) => {
  return { type: ACTIONS.BASKET_TOTAL_STAKE, payload: totalStake };
}
export const clear_basket = () => {
  return { type: ACTIONS.BASKET_CLEAR };
}
export const set_game_info = (game) => {
  return { type: ACTIONS.BASKET_SET_GAME_INFO, payload: game };
}

//=========================================================
// ACTIONS

/**
 * Refresh the basket: check validity, compute total stake, ...
 */
export const refresh = () => {
  return (dispatch, getState: RootStateFn): EBasketStatus => {
    // Refresh items
    const list: IBasketItem[] = getState().basket.itemList.map(item => dispatch(immutableRefreshItem(item)));
    dispatch(set_item_list(list));

    // Refresh values
    dispatch(set_total_stake(computeTotalPrice(list)));

    //refresh status
    const hasInvalidItem = list.findIndex(E => E.status != EBasketStatus.valid) > -1;
    const status: EBasketStatus = list.length == 0 ? EBasketStatus.empty
      : hasInvalidItem ? EBasketStatus.draw_closed
        : EBasketStatus.valid;
    dispatch(set_status(status));

    return status;
  }
}

export const resetList = () => {
  return (dispatch) => {
    let list = [];
    dispatch(set_item_list(list));
  }
}

export const addItem = (item: IBasketItem) => {
  return (dispatch, getState: RootStateFn) => {
    const list = [...getState().basket.itemList, item];
    dispatch(set_item_list(list));
    dispatch(refresh());
  }
}
export const addItemList = (itemList: IBasketItem[]) => {
  return (dispatch, getState: RootStateFn) => {
    //Add the new items in the top of the list : better for UX
    const list = [...itemList, ...getState().basket.itemList];
    dispatch(set_item_list(list));
    dispatch(refresh());
  }
}

export const removeItem = (item: IBasketItem) => {
  return (dispatch, getState: RootStateFn) => {
    let list = [...getState().basket.itemList];
    let index = list.findIndex(E => E === item);
    if (index >= 0) {
      list.splice(index, 1);
    }
    dispatch(set_item_list(list));
    dispatch(refresh());
  }
}

export const sortListByDrawingDate = (itemList: any) => {
  return itemList.sort((a, b) => {
    const dateA = new Date(a.draw.drawingDate).getTime();
    const dateB = new Date(b.draw.drawingDate).getTime();
    return dateA - dateB;
  })
}

//=========================================================
// INTERNAL FUNCTIONS

/**
 * Check the item
 * @param item the item to check (immutable)
 * @returns a new item if an update is found, the original item if no update
 */
const immutableRefreshItem = (item: IBasketItem) => {
  return (dispatch, getState: RootStateFn): IBasketItem => {

    let item_updated = { ...item };

    //-- Multiple consecutive draws : custom check
    if (item.selectedBetType.maxDrawByBet > 1) {
      const currentSessions = getState().game.currentSessions;
      //Check: enough open sessions
      const remainingSessionCount = computeActiveSessionCount(currentSessions);
      if (item.nbConsecutiveDraws > remainingSessionCount) {
        item_updated.status = EBasketStatus.not_enough_draws;
      }
      //Check: if next draw is closed, use the first open draw
      else if (!item.draw?.closingDate || dayjs(item.draw.closingDate).isBefore()) {
        const nextActiveSession = getNextActiveSession(currentSessions);
        if (nextActiveSession) {
          item_updated.draw = nextActiveSession;
          item_updated.drawId = nextActiveSession.drawId;
        } else {
          item_updated.status = EBasketStatus.not_enough_draws;
        }
      }
    }
    //-- Default check (single draw)
    else {
      if (item.draw?.closingDate && dayjs(item.draw.closingDate).isBefore()) {
        item_updated.status = EBasketStatus.draw_closed;
      }
    }
    const is_changed = item.status != item_updated.status || item.draw != item_updated.draw;
    return is_changed ? item_updated : item;
  }
}

const computeTotalPrice = (list: IBasketItem[]) => {
  return list.reduce((acc, cur) => acc + cur.price, 0);
}

const computeActiveSessionCount = (sessions: IDraw[]): number => {
  return sessions.filter(E => !E.closingDate || dayjs(E.closingDate).isAfter()).length;
}
const getNextActiveSession = (sessions: IDraw[]): IDraw | undefined => {
  return sessions.find(E => !E.closingDate || dayjs(E.closingDate).isAfter());
}