import { ENV } from 'src/env';

//=============================================================================
// API CALLS
//=============================================================================

const GAMERULES_INDEX_URL = ENV.STORAGE_URL + '/game-lottery/public/app_config/config.json';

export const loadAppConfig = (): any => {
  return fetch(GAMERULES_INDEX_URL).then(res => res.json());
}