import { ACTIONS } from '../actions/booking.actions';
import { IBookingResponse } from 'src/models/ticketing/booking.model';
export interface IBookingReducer {
  error: boolean;
  isFetching: boolean;
  errorMessage: string;
  transactionIDError: string;
  bookingStatus: string;
  bookingData: IBookingResponse;
}

const initialState = <IBookingReducer>{
  error: false,
  isFetching: true,
  errorMessage: '',
  transactionIDError: '',
  bookingStatus: '',
  bookingData: {}
}


export default function bookingReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    // STATES
    case ACTIONS.BOOKING_ERROR: {
      return {
        ...state,
        error: true,
        isFetching: false,
        errorMessage: action.payload?.type || action.payload?.failedReason,
        transactionIDError: action.payload?.operationUuid,
      }
    }
    case ACTIONS.BOOKING_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.BOOKING_FETCHING_DONE: {
      return {
        ...state,
        isFetching: false,
        bookingData: action.payload
      }
    }
    case ACTIONS.UPDATE_CURRENT_BOOKING: {
      return {
        ...state,
        isFetching: false,
        bookingStatus: action.payload.status
      }
    }
    case ACTIONS.CLEAR_STATUS: {
      return {
        ...state,
        isFetching: false,
        bookingStatus: '',
      }
    }
    default:
      return state
  }
}