import * as React from "react";

const SVGBasket = ({ fill, width, height }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M16.4484 8.08023L17.6023 15.5802C17.7576 16.5898 16.9764 17.5003 15.955 17.5003H4.84036C3.81889 17.5003 3.03775 16.5898 3.19308 15.5802L4.34692 8.08023C4.47201 7.26718 5.1716 6.66699 5.99421 6.66699H14.8012C15.6238 6.66699 16.3233 7.26718 16.4484 8.08023Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.0653 4.16667C12.0653 3.24619 11.3191 2.5 10.3986 2.5C9.4781 2.5 8.73193 3.24619 8.73193 4.16667" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
export default SVGBasket;