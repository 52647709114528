import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  readonly: {
    pointerEvents: 'none',
  },
  grid: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '11px',
    paddingRight: '11px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '59px',
      paddingRight: '59px'
    },
  },
  gridTitle: {
    color: 'grey',
    fontSize: '15px',
    marginTop: '15px',
  },
  gridPicker: {
    flexDirection: 'row',
    flexWrap: "wrap",
    justifyContent: 'space-between',
  },
  numberButton: {
    height: '48px',
    width: '48px',
    backgroundColor: '#FFF',    
    zIndex: 0,
    marginLeft: '8px',
    marginRight: '8px',
    marginBottom: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 6px 0px #00000014',
    "&:active, &:hover": {
      backgroundColor: '#FFF',
    },
    ["@media (hover: hover)"]: {
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  numberButtonPressed: {
    backgroundColor: theme.palette.primary.main,
    "&:hover, &:active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  number: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#272727',

  },
  numberPressed: {
    color: '#FFF',
  },
})