import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0, //scroll
  },
  header: {
    paddingLeft: '11px',
    paddingRight: '11px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '59px',
      paddingRight: '59px'
    },
  },
  //BetType
  betTypeBox: {
    background: theme.palette.sticker.main,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '16px',
    height: '56px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '24px',
    justifyContent: 'space-between'
  },
  betTypeText: {
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 700,
  },
  gridList: {
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '80px', // for the validate footer
    },
  },
  //Flash
  flashButtonBox: {
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  flashButton: {
    backgroundColor: '#272727',
    '&:hover': { backgroundColor: '#272727' },
    borderRadius: '16px',
    padding: '15px',
    [theme.breakpoints.up('md')]: {
      width: '200px',
      height: '40px',
    },
  },
  flashButtonText: {
    color: '#FFF',
    fontWeight: 700,
    textTransform: 'initial',
    fontSize: '18px',
    marginLeft: '8px',
  },
  //Title
  stepTitle: {
    marginBottom: '16px',
  },
  stepTitleText: {
    fontWeight: 700,
    fontSize: '20px',
  },
  stepTitleImportantText: {
    fontWeight: 700,
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  //Validate Button
  validateContainer: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  validateRow: {
    margin: '16px',
    marginBottom: '16px',
    backgroundColor: 'transparent',
  },
  validateButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.main },
    '&:disabled': { backgroundColor: theme.palette.primary.light },
    height: '48px',
    width: '100%',
    maxWidth: '500px',
    borderRadius: '16px',
    [theme.breakpoints.up('md')]: {
      width: '320px',
    }
  },
  validateButtonText: {
    color: '#FFF',
    fontWeight: 700,
    textTransform: 'initial',
    fontSize: '18px',
    marginLeft: '8px',
  },
})