import { Box, useTheme } from '@mui/material';
import { useNavigate, useParams, useSearchParams, } from 'react-router-dom';
import { routePath } from '../../routes';
import { useEffect, useState } from 'react';
import { getBetTypeList, getGame } from '../../store/actions/game.action';
import { getPlayerInfo, startPlayerTokenAutoRefresh } from '../../store/actions/player.actions';
import { loadAppConfig } from 'src/store/actions/app.actions';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../common/i18n/i18n';
import { useTranslation } from "react-i18next";
import { RootState } from '../../store/root.reducer';
import { makeStyle } from './appLoader.style';
import { set_app_entry_params } from 'src/store/actions/app.actions';
import { AppEntryParams } from 'src/models/core/app.model';
import { StorageKeys } from 'src/models/core/storage.model';
import Lottie from 'react-lottie';
import * as BasicLoto from '../../assets/animated_icons/basic_loto.json';
import GenericError from 'src/component/common/genericError/genericError.comp';
import ExitButton from 'src/component/core/exitButton/exitButton.comp';

const AppLoaderPage = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BasicLoto,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  let { gameId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>()
  const [searchParams] = useSearchParams();
  const [isError, setIsError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const game = useSelector((state: RootState) => state.game);
  const app = useSelector((state: RootState) => state.app);
  const playerStore = useSelector((state: RootState) => state.player);

  //====================================== Effects

  useEffect(() => {
    loadEntryParams();
    loadAPIData();
    dispatch(startPlayerTokenAutoRefresh());
  }, [])

  useEffect(() => {
    const _isError = playerStore.error || game.error || game.stateBetTypes.isError || app.error;
    const _isFetching = !_isError && (game.isFetching || game.stateBetTypes.pending || playerStore.isFetching || app.isFetching);
    const _isLoaded = !_isFetching && playerStore.isConnected && game.game && game.betTypes?.length > 0 && Object.keys(app.config).length > 0;

    setIsError(_isError);
    setIsFetching(_isFetching);

    if (!_isFetching && !_isError && game.game && !game.betTypes?.length) {
      dispatch(getBetTypeList(game.game.gameType, gameId));
    }
    else if (_isLoaded) {
      navigate(routePath.betBuilder);
    }
  }, [game, playerStore, app.config])

  //====================================== Functions

  const reloadApp = () => {
    document.location.reload()
  }

  const loadEntryParams = () => {
    const entryParams: AppEntryParams = {
      bCtoken: searchParams.get("token") || "",
      gameId: parseInt(gameId || "0") || 0,
      mode: searchParams.get("mode") || "",
      isMobile: searchParams.get("isMobile") === "True" ? true : false,
      language: searchParams.get("language") || "en",
      exitUrl: searchParams.get("exitURL") || ""
    }
    dispatch(set_app_entry_params(entryParams));

    localStorage.setItem(StorageKeys.gameId, gameId || "");
    localStorage.setItem(StorageKeys.bCtoken, entryParams.bCtoken)
    localStorage.setItem(StorageKeys.mode, entryParams.mode);
    localStorage.setItem(StorageKeys.language, entryParams.language);

    setLanguage(entryParams.language);
  }
  const loadAPIData = () => {
    //Note: 3 data to load : game, player, betTypes. betTypes depends on the game type.
    dispatch(getGame(gameId))
    dispatch(getPlayerInfo())
  }

  //====================================== Render
  return (
    <Box sx={style.container}>
      {isFetching &&
        <Box>
          <Lottie
            options={defaultOptions}
            height={400}
            width={400}
          />
        </Box>
      }
      {isError &&
        <>
          <Box sx={style.exitButtonContainer}>
            <ExitButton />
          </Box>
          <GenericError
            onAction={reloadApp}
            actionText={t('Error.pageReload')}
            titleStrongPrefix={t('Error.technicalError') + ','}
            title={t('Error.reloadGame')}
          />
        </>
      }
    </Box>
  );
}

export default AppLoaderPage;