import { IBetType } from 'src/models/lottery/betType.models';
import { BetBuilderSteps } from '../../models/lottery/betBuilder.model';
import { ACTIONS } from '../actions/betBuilder.actions';
import { titleCase } from 'src/common/utils/format.utils';
import { IGridSelection, TGridSelectionsObj } from 'src/models/lottery/grid.model';
import { TDrawsAsObject } from 'src/models/lottery/draw.model';

interface IBetBuilderReducer {
  currentStep: number;
  firstStep: number;
  isCustomStakeSelected: false;
  isPermutationEnabled: false;
  combinationCount: number;
  selectedStake: number;
  selectedSessions: TDrawsAsObject;
  selectedSessionCount: number;
  selectedGrids: TGridSelectionsObj;
  selectedConsecutiveDrawCount: number;
  selectedBetType: IBetType;
  totalPrice: number;
  maxGain: number;
  isEditionMode: boolean;
  betIdToEdit: string;
}

const initialState: IBetBuilderReducer = {
  currentStep: BetBuilderSteps.NONE,
  firstStep: BetBuilderSteps.SESSION,
  isCustomStakeSelected: false,
  isPermutationEnabled: false,
  combinationCount: 0,
  selectedStake: 0,
  selectedSessions: {},
  selectedSessionCount: 0,
  selectedGrids: {},
  selectedConsecutiveDrawCount: 1,
  selectedBetType: <IBetType>{},
  totalPrice: 0,
  maxGain: 0,
  isEditionMode: false,
  betIdToEdit: '',
}

// Reducer to catch the actions

export default function betBuilderReducer(state = initialState, action): IBetBuilderReducer {
  switch (action.type) {
    //------------------
    case ACTIONS.BETBUILDER_BETTYPE_SELECTED: {
      const betType = (action.payload ? { ...action.payload } : null) as IBetType;
      if (betType?.name) betType.name = titleCase(betType.name);
      return {
        ...state,
        selectedBetType: betType,
        selectedGrids: {}, // reset
      }
    }
    case ACTIONS.BETBUILDER_CONSECUTIVE_DRAWS: {
      return { ...state, selectedConsecutiveDrawCount: action.payload }
    }
    case ACTIONS.BETBUILDER_CLEAR_SELECTED_GRID_NUMBERS: {
      const selectedGrids: TGridSelectionsObj = Object
        .values(state.selectedGrids)
        .reduce((acc: TGridSelectionsObj, cur: IGridSelection) => ({ ...acc, [cur.gridName]: cur }), {});
      return { ...state, selectedGrids }
    }
    case ACTIONS.BETBUILDER_PERMUTATION: {
      return { ...state, isPermutationEnabled: action.payload.isActivated, combinationCount: action.payload.combinationCount }
    }
    case ACTIONS.BETBUILDER_CURRENT_STEP: {
      return { ...state, currentStep: action.payload }
    }
    case ACTIONS.BETBUILDER_FIRST_STEP: {
      return { ...state, firstStep: action.payload }
    }
    case ACTIONS.BETBUILDER_GRIDS_SELECTED: {
      return { ...state, selectedGrids: action.payload }
    }
    case ACTIONS.BETBUILDER_RESET: {
      return { ...initialState }
    }
    case ACTIONS.BETBUILDER_SESSIONS_SELECTED: {
      return {
        ...state,
        selectedSessions: action.payload,
        selectedSessionCount: Object.values(action.payload)?.length || 0,
      }
    }
    case ACTIONS.BETBUILDER_STAKE_SELECTED: {
      return { ...state, selectedStake: action.payload }
    }
    case ACTIONS.BETBUILDER_TOTAL_PRICE: {
      return { ...state, totalPrice: action.payload }
    }
    case ACTIONS.BETBUILDER_MAX_GAIN: {
      return { ...state, maxGain: action.payload }
    }
    case ACTIONS.BETBUILDER_EDITION_MODE: {
      return { ...state, isEditionMode: action.payload.enabled, betIdToEdit: action.payload.betId }
    }
    default:
      return state
  }
}