import { IGameRuleSlide } from 'src/models/lottery/gameRules.model';
import { ACTIONS } from '../actions/gameRules.actions';
import { IPayLine } from 'src/models/lottery/payLine.model';

export interface IGameRulesReducer {
  // Metadata
  error: boolean;
  isFetching: boolean;
  errorMessage: string;
  // Data
  allSlides: IGameRuleSlide[],
  currentPaylines: IPayLine[],
  customStakeAmount: string | null,
}

const initialState = <IGameRulesReducer>{
  error: false,
  isFetching: true,
  errorMessage: '',
  allSlides: [],
  currentPaylines: [],
  customStakeAmount: null,
}


export default function playerReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    // STATES
    case ACTIONS.GAMERULES_FAILED: {
      return {
        ...state,
        error: true,
        isFetching: false,
        isConnected: false,
        errorMessage: action.payload?.message
      }
    }
    case ACTIONS.GAMERULES_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.GAMERULES_DATA: {
      return {
        ...state,
        isConnected: true,
        allSlides: action.payload || [],
      }
    }
    case ACTIONS.CURRENT_PAYLINES_RULES: {
      return {
        ...state,
        currentPaylines: action.payload,
      }
    }
    case ACTIONS.UPDATE_CUSTOM_STAKE_AMOUNT: {
      return {
        ...state,
        customStakeAmount: action.payload,
      }
    }
    default:
      return state
  }
}