import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EErrorType } from "src/models/core/app.model";
import { IDraw } from "src/models/lottery/draw.model";
import { routePath } from "src/routes";
import { RootState } from "src/store/root.reducer";
import { findExpiredSessions, removeExpiredSessions, sessionListToObject } from "src/store/services/game.service";
import * as betBuilderActions from "src/store/actions/betBuilder.actions";
import * as gameActions from "src/store/actions/game.action";

export function useSessionExpirationChecker() {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const currentGame = useSelector((state: RootState) => state.game.game);
  const currentSesions = useSelector((state: RootState) => state.game.currentSessions);
  const selectedSessions = useSelector((state: RootState) => state.betBuilder.selectedSessions);

  //======================================

  const onExpiredSessionClick = (session: IDraw) => {
    if (selectedSessions[session.drawId]) {
      dispatch(gameActions.set_current_sessions(removeExpiredSessions(currentSesions)));
    } else {
      goErrorPage_expiredSessionSelected();
    }
  }

  const checkExpiredSessions = (): boolean => {
    const expiredSessions: IDraw[] = findExpiredSessions(currentSesions);
    const hasExpiredSessions = expiredSessions.length > 0;
    if (hasExpiredSessions) {
      dispatch(gameActions.set_current_sessions(removeExpiredSessions(currentSesions)));
      dispatch(gameActions.getSessionsFuture(currentGame.gameType, currentGame.gameId));
    }
    return hasExpiredSessions;
  }

  const checkExpiredSessionsSelected = (): boolean => {
    const sessionList = Object.values(selectedSessions);
    const expiredSessions: IDraw[] = findExpiredSessions(sessionList);
    const hasExpiredSessions = expiredSessions.length > 0;
    if (hasExpiredSessions) {
      const validSessionList = removeExpiredSessions(sessionList);
      dispatch(betBuilderActions.set_sessions_selected(sessionListToObject(validSessionList)));
      goErrorPage_expiredSessionSelected();
    }
    return hasExpiredSessions;
  }

  const goErrorPage_expiredSessionSelected = () => {
    navigate({ pathname: routePath.error, search: '?errorType=' + EErrorType.session_expired })
  }

  //======================================
  
  return ({
    onExpiredSessionClick,
    checkExpiredSessions,
    checkExpiredSessionsSelected
  });
}