import { Theme } from "@mui/material";
import commonStyle from "src/common/style/commonStyle";

export const makeStyle = (theme: Theme) => ({
  container: {
    height: '100%', //scroll
  },
  scrollItemPosition: {
    fontSize: '16px',
    fontWeight: 700,
    paddingLeft: '16px',
    paddingBottom: '8px',
  },
  sliderContainer: {
    margin: 'auto',
    paddingLeft: '11px',
    paddingRight: '11px',
    flexDirection: 'row',
    display: 'flex',
    overflowX: 'auto',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      overflowX: 'hidden',
      ...commonStyle.innerScrollHeight(theme, 'left'),
    },
  },
  sliderContent: {
    //manage the left scrollbar
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'initial',
      ...commonStyle.leftScrollContent,
    }

  },
  betBox: {
    padding: '16px',
    minWidth: '250px',
    marginBottom: '16px',
    marginRight: '16px',
    borderRadius: '24px',
    border: 'none',
    boxShadow: '0px 6px 12px 0px rgba(39, 39, 39, 0.08)',
    background: '#FFF',
  },
  betBoxLarge: {
    marginRight: 0,
  },
  betTypeText: {
    fontSize: 24,
    fontWeight: 700,
    color: "#000",
  },
  dateText: {
    fontSize: 12,
    fontWeight: 700,
    color: "#BBB",
  },
  gridBox: {
    backgroundColor: '#F5F5F5',
    borderRadius: '8px',
    padding: '12px',
    marginTop: '16px',
  },
  gridtitle: {
    fontSize: 16,
    fontWeight: 700,
    color: "#000",
    whiteSpace: 'nowrap',
  },
  permutationText: {
    fontSize: 12,
    fontWeight: 700,
    color: "#BBB",
    marginBottom: '4px',
  },
  numbersContainer: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },
  numbersBox: {
    width: 35,
    height: 35,
    bgcolor: "#000",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: '8px',
    marginTop: '8px'
  },
  numberText: {
    fontWeight: 700,
    color: '#FFF',
    fontSize: 16,
  },
})