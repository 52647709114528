import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import { Box, Divider, Typography, useTheme, Grid } from "@mui/material";
import { makeStyle } from "./gameRulesSlider.style";
import { useEffect, useState } from "react";
import * as gameRulesActions from "src/store/actions/gameRules.actions";
import * as gameRulesService from "src/store/services/gameRules.service";
import { EGameRuleType, IGameRuleSlide } from "src/models/lottery/gameRules.model";
import { IBetType } from "src/models/lottery/betType.models";


type Props = {
  betTypeId: string,
}

const GameRulesSlider = ({
  betTypeId,
}: Props): JSX.Element => {

  const dispatch = useDispatch<any>();
  const theme = useTheme();
  const style = makeStyle(theme);

  //====================================== States

  const gameRuleAllSlides: IGameRuleSlide[] = useSelector((state: RootState) => state.gameRules.allSlides);
  const betTypeList: IBetType[] = useSelector((state: RootState) => state.game.betTypesActive);

  const [slideTitle, setSlideTitle] = useState('');
  const [slides, setSlides] = useState<IGameRuleSlide[]>([]);

  //====================================== Effects

  useEffect(() => {
    dispatch(gameRulesActions.loadGameRules());
  }, [])
  
  useEffect(() => {
    const betType = betTypeList.find(E => E.betTypeId == betTypeId);
    setSlideTitle(betType?.name);
  }, [betTypeId])

  useEffect(() => {
    const slides = gameRulesService.findSlides(gameRuleAllSlides, { groupType: EGameRuleType.betType, groupId: betTypeId });
    setSlides(slides);
  }, [gameRuleAllSlides, betTypeId])

  //====================================== Functions


  //====================================== Render

  return (
    <Grid container sx={style.container}>
      {slides.map((slide, key) => (
        <Box sx={style.slide} key={key}>
          {key > 0 &&
            <Divider sx={style.slideDivider} flexItem />
          }
          {slide.slideImage &&
            <Box sx={style.slideImageContainer}>
              <img alt="img-slide" src={slide.slideImage} />
            </Box>
          }
          {slideTitle &&
            <Typography sx={style.slideTitle}>
              {slideTitle}:
            </Typography>
          }
          {slide.slideText &&
            <Typography sx={style.slideText}>
              {slide.slideText}
            </Typography>
          }
        </Box>
      ))}
    </Grid>
  )
}

//======================================
export default GameRulesSlider;