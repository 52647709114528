import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import * as betBuilderActions from "src/store/actions/betBuilder.actions";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyle } from "./gridPicker.style";
import { IBetType } from "src/models/lottery/betType.models";
import SVGFlash from "src/assets/icons/SVGFlash";
import { sortNumber } from "src/common/utils/format.utils";
import { TGridSelectionsObj } from "src/models/lottery/grid.model";
import GridContent from "./gridContent.comp";
import GridPermutationStatus from "./gridPermutationStatus.comp";
import SessionCountdownTimer from "src/component/lottery/betBuilder/sessionCountdownTimer/sessionCountdownTimer.comp";
import { sleep } from "src/common/utils/dom.utils";

const GridPicker = ({
  goNextStep,
}) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  //====================================== States

  const [isFormValid, setFormValid] = useState(false);
  const [isCountdownActivated, setCountdownActivated] = useState(false);
  const [isBuildingFlashNumbers, setIsBuildingFlashNumbers] = useState(false);

  const selectedBetType: IBetType = useSelector((state: RootState) => state.betBuilder.selectedBetType);
  const betTypesActive = useSelector((state: RootState) => state.game.betTypesActive);
  const selectedGrids: TGridSelectionsObj = useSelector((state: RootState) => state.betBuilder.selectedGrids);
  const currentSessions = useSelector((state: RootState) => state.game.currentSessions);
  const gameName = useSelector((state: RootState) => state.game.game.name);

  //====================================== Effects

  useEffect(() => {
    if (selectedBetType) {
      const defaultStake = selectedBetType.stake || selectedBetType.defaultPrices?.[0] || selectedBetType.minBetAmount;
      dispatch(betBuilderActions.set_stake_selected(defaultStake));
      dispatch(betBuilderActions.initializeSelectedGrids(selectedBetType));
      setCountdownActivated(selectedBetType.maxDrawByBet > 1)
    }

  }, [selectedBetType])

  useEffect(() => {
    checkFormValid();
  }, [selectedGrids])

  //====================================== Functions

  const validateGrid = () => {
    goNextStep();
  }

  const checkFormValid = () => {
    const _isValid = selectedGrids && Object.values(selectedGrids).reduce((acc, grid: any) => {
      return acc && grid
        && grid.selectedNumbers?.length >= grid.requiredNumber
        && grid.selectedNumbers?.length <= grid.requiredNumber + grid.maxAdditional;
    }, true);
    setFormValid(_isValid);
  }

  const buildFlashNumbers = async () => {
    if (isBuildingFlashNumbers) {
      return;
    }
    setIsBuildingFlashNumbers(true);

    try {
      for (const elem of selectedBetType.listOfGridParameters) {
        // Reset the numbers 
        dispatch(betBuilderActions.updateSelectedGrid(elem.gridName, []));

        const currentGrid = selectedGrids[elem.gridName];
        const { listOfLottoNumbers, requiredNumber } = currentGrid;

        // random player numbers
        let randomNumbersSet = new Set<number>();
        while (randomNumbersSet.size < requiredNumber) {
          randomNumbersSet.add(listOfLottoNumbers[Math.floor(Math.random() * listOfLottoNumbers.length)]);
        }
        const selectedNumbers = [...randomNumbersSet];
        selectedNumbers.sort(sortNumber);

        for (const num of selectedNumbers) {
          await sleep(50);
          dispatch(betBuilderActions.updateSelectedGridFlash(elem.gridName, num));
        }
      }
    } finally {
      setIsBuildingFlashNumbers(false);
    }
  }

  //====================================== Render

  return (
    <Box sx={style.container}>

      <Grid container sx={style.header}>

        <Grid item xs={12}>
          <Box sx={style.betTypeBox}>
            <Typography sx={style.betTypeText}>
              {betTypesActive?.length == 1 ? gameName : selectedBetType?.name}
            </Typography>
            {isCountdownActivated &&
              <SessionCountdownTimer style={style.betTypeText} sessions={currentSessions} />
            }
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={style.stepTitle}>
          <Typography sx={style.stepTitleText}>
            {t('Grid.select_your_numbers_1')}
            <Typography sx={style.stepTitleImportantText} component={'label'}>
              {t('Grid.select_your_numbers_2')}
            </Typography>
          </Typography>

          {isLarge && <GridPermutationStatus />}
        </Grid>

        <Grid item xs={12} md={6} sx={style.flashButtonBox}>
          <Button
            sx={style.flashButton}
            onClick={buildFlashNumbers}
            fullWidth
            disableRipple
          >
            <SVGFlash stroke='#FFF' />
            <Typography sx={style.flashButtonText}>
              Flash
            </Typography>
          </Button>
        </Grid>

        {isLarge == false && <GridPermutationStatus />}

      </Grid>

      <Box sx={style.gridList}>
        {
          selectedBetType?.listOfGrids.map((grid, gridIndex) =>
            <GridContent grid={grid} gridIndex={gridIndex} key={gridIndex} readonly={isBuildingFlashNumbers} />
          )
        }
      </Box>

      <Box sx={style.validateContainer}>
        <Box sx={style.validateRow}>
          <Button
            sx={style.validateButton}
            onClick={validateGrid}
            disabled={!isFormValid}
            disableRipple
          >
            <Typography sx={style.validateButtonText}>
              {t('Grid.validate_selection')}
            </Typography>
          </Button>
        </Box>
      </Box>

    </Box>
  )
}

//======================================
export default GridPicker;