import * as React from "react";

const SVGInfo = ({ fill, width, height }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
      <path d="M10.2002 9.58301V13.7497" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.2002 6.25828L10.2085 6.24902" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.2015 18.3337C14.8038 18.3337 18.5348 14.6027 18.5348 10.0003C18.5348 5.39795 14.8038 1.66699 10.2015 1.66699C5.59912 1.66699 1.86816 5.39795 1.86816 10.0003C1.86816 14.6027 5.59912 18.3337 10.2015 18.3337Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
export default SVGInfo;
