import { Theme } from "@mui/material";
import commonStyle from "src/common/style/commonStyle";

export const makeStyle = (theme: Theme) => ({
  container: {
    flex: 1,
    minHeight: 0, //scroll
  },
  body: {
    paddingLeft: '11px',
    paddingRight: '11px',
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  stepTitle: {
    fontSize: '16px',
    marginBottom: 10,
    color: '#404040',
  },
  //
  betTypeListContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',

    [theme.breakpoints.up('lg')]: {
      marginLeft: '59px',
      paddingRight: '50px',
      overflowY: "auto",
      maxHeight: "645px",
    },
    ...commonStyle.slimScrollBar(theme),
  },
  betTypeCollapse: {
    background: `linear-gradient(90deg, ${theme.palette.shades[600]} 0%, ${theme.palette.shades[500]} 100%)`,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    marginBottom: '16px',
    borderRadius: '16px',
    width: '100%',
    height: '48px',
    "&:hover, &:active": {
      background: `linear-gradient(90deg, ${theme.palette.shades[600]} 0%, ${theme.palette.shades[500]} 100%)`,
    },
  },
  betTypeCollapseOpen: {
    background: `linear-gradient(90deg, ${theme.palette.shades[900]} 0%, ${theme.palette.shades[600]} 100%)`,
    "&:hover, &:active": {
      background: `linear-gradient(90deg, ${theme.palette.shades[900]} 0%, ${theme.palette.shades[600]} 100%)`,
    },
  },
  betTypeCollapseText: {
    overflow: 'hidden',
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'none'
  },
  betTypeButtonBox: {
    height: '48px',
    display: 'flex',
    marginBottom: '10px'
  },
  betTypeButtonLeft: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
    flex: 6,
    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.04)',
    "&:hover, &:active": {
      backgroundColor: '#FFF',
    },
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  betTypeButtonTextLeft: {
    color: '#272727',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
  },
  betTypeButtonRight: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
    minWidth: '48px',
    marginLeft: '10px',
    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.04)',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    "&:hover, &:active": {
      backgroundColor: '#FFF',
      border: '1px solid rgba(0, 0, 0, 0.10)',
    },
  },
  categoryArrowIcon: {
    width: '24px',
    height: '24px',
  },
  categoryArrowIconOpen: {
    transform: 'rotate(180deg)',
  },
  topBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '24px',
    height: '96px',
    background: theme.palette.sticker.main,
    marginBottom: '32px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '59px',
      marginRight: '59px'
    },
  },
  topBoxTitleText: {
    color: "#FFF",
    fontSize: '24px',
    fontWeight: 800,
    marginLeft: '16px',
    marginTop: '30px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '10px',
    },
  },
  topBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  gameName: {
    marginTop: '-10px',
    marginRight: "5px",
    color: '#FFF',
    fontSize: '32px',
    fontWeight: 800
  },
  modalRules: {
    borderRadius: '16px',
    background: '#FFF',
    width: '328px',
    height: '496px'
  }
})