import dayjs from "dayjs";

interface CacheControlElement {
  key: string;
  date: dayjs.Dayjs;
  timeout: number; //seconds
}

let _cache_control = new Map<string, CacheControlElement>();
const _cache_default_timeout = 300;

export function cache_create(key: string, timeout = _cache_default_timeout, date = dayjs()): CacheControlElement {
  return { key, timeout, date } as CacheControlElement;
}

export function cache_expired(key: string): boolean {
  let cache = _cache_control.get(key);
  return cache == undefined || dayjs() > cache.date;
}

export function cache_reset(key: string, timeout: number = _cache_default_timeout) {
  let cache = _cache_control.get(key);
  if (cache) {
    cache.date = dayjs().add(cache.timeout, "seconds");
  } else {
    _cache_control.set(key, { key, timeout, date: dayjs().add(timeout, "seconds") });
  }
}

export function cache_reset_from_model(model: CacheControlElement, key_suffix: string | number = '') {
  cache_reset(model.key + key_suffix, model.timeout);
}