/**
 * Push here shared styles and settings
 */
//=========================================================

import { Theme } from "@mui/material";


//=========================================================

/**
 * Contains all the common styles
 */
export const commonStyle = {

  /** use a slim and colored scrollbar */
  slimScrollBar: (theme: Theme, position?: 'left') => ({
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#D9D9D9',
      borderRadius: '32px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '32px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '32px',
    },
    direction: position == 'left' ? 'rtl' : 'inherit',
    paddingRight: position != 'left' ? '8px' : null,
    paddingLeft: position == 'left' ? '8px' : null,
  }),

  /** activate the scroll and use the custom scrollar */
  innerScrollHeight: (theme: Theme, position?: 'left') => ({
    overflowY: 'auto',
    height: '100%',
    ...commonStyle.slimScrollBar(theme, position),
  }),

  /* position the scrollbar to the left */
  leftScrollWrapper: {
    direction: 'rtl', //revert direction to show the scroll on the left...
  },
  leftScrollContent: {
    direction: 'ltr', //...restore the direction inside
  }
};

export default commonStyle;