import { Button, Typography, useTheme } from "@mui/material";
import SVGArrowBackButton from "src/assets/icons/SVGArrowBackButton";
import { useTranslation } from "react-i18next";
import { makeStyle } from "./backButton.style";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";

const BackButton = ({
  onClick,
}): JSX.Element => {

  const { t } = useTranslation();
  const style = makeStyle(useTheme());

  const showExitButton = useSelector((state: RootState) => state.app.showExitButton);

  return (
    <Button
      disableRipple
      sx={[
        style.backButton,
        !showExitButton && style.backButtonToTop
      ]}
      onClick={onClick}
    >
      <SVGArrowBackButton />
      <Typography sx={style.backButtonText}>{t('back')}</Typography>
    </Button>
  );
}

export default BackButton;
