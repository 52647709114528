
export function capitalize(s: string): string {
  return s.substring(0, 1).toUpperCase() + s.slice(1).toLowerCase();
}

export function titleCase(text: string) {
  return text
    ?.toLowerCase()
    .replace(/^[-_ ]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_ ]+(.)/g, (_, c) => ' ' + c.toUpperCase());
}

export function sortNumber(a: number, b: number) {
  return a - b;
}

export function isNullOrEmptyObject(o: any) {
  return o == null || Object.keys(o).length == 0;
}

/**
 * Add leading zeros to the stringified number.
 * Ex: f(8) == f(8, 2) == '08'
 */
export function zeroFill(n: number, maxLength: number = 2): string {
  return n.toString().padStart(maxLength, '0');
}