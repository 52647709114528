export interface AppEntryParams {
  bCtoken: string,
  gameId: number,
  language: string,
  mode: string,
  exitUrl: string,
  isMobile?: boolean
}

export enum EErrorType { _none, technical, no_session, session_expired, booking_failed, booking_failed_refund }

export interface IAppConfig {
  betting: IBetting;
  games: IGamesColors[];
  support: ISupport;
}

export interface IBetting {
  staggered_stake: number;
  periodInDays: number;
}

export interface IGamesColors {
  primaryColor: string;
  sticker: string;
  shades: {[token:string]: string};
}

export interface ISupport {
  email: string;
  phoneNumber: string;
  taxes: ITaxes;
}

export interface ITaxes {
  infos: {[language: string]: string};
}
