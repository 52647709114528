import { Box, Button, Typography, useTheme } from "@mui/material";
import { makeStyle } from "./topBar.style";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { routePath } from "../../../routes";
import SVGInfo from '../../../assets/icons/SVGInfo';
import SVGClover from "../../../assets/icons/SVGClover";
import SVGBasket from "../../../assets/icons/SVGBasket";
import SVGTicket from "../../../assets/icons/SVGTicket";
import SVGWallet from '../../../assets/icons/SVGWallet';
import { useTranslation } from "react-i18next";
import ExitButton from "../exitButton/exitButton.comp";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";

export enum e_tab { _none, betBuilder, basket, info, myBets, myWallet }

const TopBar = (): JSX.Element => {

  const theme = useTheme();
  const style = makeStyle(theme);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState<e_tab>();

  const basketLength = useSelector((state: RootState) => state.basket.itemList.length);

  useEffect(() => {
    const newActiveTab = getActiveTabFromPath(location?.pathname);
    newActiveTab != e_tab._none && setActiveTab(newActiveTab);
  }, [location])



  const getActiveTabFromPath = (locationPathName: string) => {
    const basePath = '/' + locationPathName.split('/').at(1);
    switch (basePath) {
      case routePath.basket: return e_tab.basket;
      case routePath.betBuilder: return e_tab.betBuilder;
      case routePath.info: return e_tab.info;
      case routePath.myBets: return e_tab.myBets;
      case routePath.myWallet: return e_tab.myWallet;
      default: return e_tab._none;
    }
  }

  const onTabClick = (tab: e_tab) => {
    switch (tab) {
      case e_tab.basket:
        navigate(routePath.basket);
        break;
      case e_tab.betBuilder:
        navigate(routePath.betBuilder);
        break;
      case e_tab.myWallet:
        navigate(routePath.myWallet);
        break;
      case e_tab.info:
        navigate(routePath.info);
        break;
      case e_tab.myBets:
        navigate(routePath.myBets);
        break;
    }
  }

  return (
    <Box sx={style.headerContainer}>
      <Box sx={style.headerContent}>

        <Box sx={style.exitButton}>
          <ExitButton />
        </Box>

        <Button
          disableRipple
          onClick={() => onTabClick(e_tab.betBuilder)}
          sx={[style.button, activeTab == e_tab.betBuilder && style.buttonActive]}
          test-id="betbuilder-btn"
        >
          <SVGClover fill={activeTab == e_tab.betBuilder ? '#FFF' : '#000'} />
          <Typography sx={[style.buttonText, activeTab == e_tab.betBuilder && style.buttonTextActive]}>{t('draw')}</Typography>
        </Button>
        <Button
          disableRipple
          onClick={() => onTabClick(e_tab.info)}
          sx={[style.button, activeTab == e_tab.info && style.buttonActive]}
          test-id="info-btn"
        >
          <SVGInfo fill={activeTab == e_tab.info ? '#FFF' : '#000'} />
          <Typography sx={[style.buttonText, activeTab == e_tab.info && style.buttonTextActive]}>{t('rules')}</Typography>
        </Button>
        <Button
          disableRipple
          onClick={() => onTabClick(e_tab.myWallet)}
          sx={[style.button, activeTab == e_tab.myWallet && style.buttonActive]}
          test-id="wallet-btn"
        >
          <SVGWallet fill={activeTab == e_tab.myWallet ? '#FFF' : '#000'} />
          <Typography sx={[style.buttonText, activeTab == e_tab.myWallet && style.buttonTextActive]}>{t('wallet')}</Typography>
        </Button>
        <Button
          disableRipple
          onClick={() => onTabClick(e_tab.basket)}
          sx={[style.button, activeTab == e_tab.basket && style.buttonActive]}
          test-id="basket-btn"
        >
          <SVGBasket fill={activeTab == e_tab.basket ? '#FFF' : '#000'} />
          <Typography sx={[style.buttonText, activeTab == e_tab.basket && style.buttonTextActive]}>{t('Basket.basket')}</Typography>
          {basketLength > 0 &&
            <Box sx={style.basketCounter}>{basketLength}</Box>
          }
        </Button>
        <Button
          disableRipple
          onClick={() => onTabClick(e_tab.myBets)}
          sx={[style.button, activeTab == e_tab.myBets && style.buttonActive]}
          test-id="bets-btn"
        >
          <SVGTicket fill={activeTab == e_tab.myBets ? '#FFF' : '#000'} />
          <Typography sx={[style.buttonText, activeTab == e_tab.myBets && style.buttonTextActive]}>{t('tickets')}</Typography>
        </Button>
      </Box>

    </Box >
  )
}

export default TopBar;