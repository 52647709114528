import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  backButton: {
    marginBottom: '14px',
    "&:hover, &:active": {
      backgroundColor: theme.palette.background.default,
    },
  },
  backButtonToTop: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '24px',
      left: '32px',
      zIndex: '300',
    },
  },
  backButtonText: {
    textTransform: 'none',
    paddingLeft: '8px',
    fontSize: '14px',
    fontWeight: 700,
    color: "#272727"
  },
})