import axios from 'axios';
import { ENV } from '../../env';
import dayjs from 'dayjs';

const API_TIMEOUT = 60000;
const STORAGE_KEYCLOAK_TOKEN_KEY = 'gl_keycloakToken';
let keycloak_data: any;

export const PLATFORM_LOTTERY = 'WEB_LOTTERY';

//========================================================= Private

const storeTokenObjectToStorage = (tokenObj: any) => {
  if (tokenObj)
    localStorage.setItem(STORAGE_KEYCLOAK_TOKEN_KEY, JSON.stringify(tokenObj));
  else
    localStorage.removeItem(STORAGE_KEYCLOAK_TOKEN_KEY);
}

const setKPApiAuthData = (axiosInstance: any, authData: any) => {

  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${authData.access_token}`;

  const dthExpiration = dayjs().add(authData.expires_in, 'seconds').toISOString();
  keycloak_data = { ...authData, dthExpiration };

  storeTokenObjectToStorage(keycloak_data);
}

const kp_axios = function () {

  // Create Axios instance
  let instance = axios.create({
    timeout: API_TIMEOUT,
  });
  // Load token from storage
  const tokenObject = JSON.parse(<string>localStorage.getItem(STORAGE_KEYCLOAK_TOKEN_KEY));
  if (tokenObject) {
    setKPApiAuthData(instance, tokenObject);
    const token = `${tokenObject.token_type} ${tokenObject.access_token}`;
    instance.defaults.headers.post['Authorization'] = token;
  }
  // Request interceptor : add keycloak token
  instance.interceptors.request.use(async request => {
    try {
      if (keycloak_data?.dthExpiration && dayjs(keycloak_data?.dthExpiration).isBefore()) {
        let ret = await requestKeycloakToken();
        setKPApiAuthData(instance, ret.data);
        request.headers['Authorization'] = `Bearer ${ret.data.access_token}`;
      }
    }
    catch (err) {
      console.error("TOKEN ERROR", err);
    }
    return request;
  });

  // Response interceptor : manage session/token expiration
  instance.interceptors.response.use(undefined, async err => {

    if (typeof err === "string" || err.message === "Network Error" || err.code === "ECONNABORTED") {
      // Network error or timeout
    } else if (err.response?.status == 401 && err.response?.data?.errorId == undefined && !err.config["is_retry"]) {
      // Session/Token error
      console.error('api authorization error : invalid token', err);
      err.config['is_retry'] = true;
      let retKeycloak = await requestKeycloakToken();
      setKPApiAuthData(instance, retKeycloak.data);
      //Note: update the authorization header of the current request with the new auth token
      err.config.headers['Authorization'] = `Bearer ${retKeycloak.data.access_token}`;
      let ret = await axios(err.config);
      return ret;
    }
    console.error('API Axios error', err);
    // bubble up the error
    return Promise.reject(err);
  });

  return instance;
}();

//========================================================= Public

/**
 * Axios instance dedicated to KP apis
 */
export default kp_axios;

export const requestKeycloakToken = async () => {

  const { CLIENT_ID, CLIENT_SECRET, KEYCLOAK_USERNAME, KEYCLOAK_PASSWORD, KEYCLOAK_AUTH_URL } = ENV;

  const bodyEncoded = new URLSearchParams();
  bodyEncoded.append('grant_type', 'password');
  bodyEncoded.append('client_id', <string>CLIENT_ID);
  bodyEncoded.append('client_secret', <string>CLIENT_SECRET);
  bodyEncoded.append('username', <string>KEYCLOAK_USERNAME);
  bodyEncoded.append('password', <string>KEYCLOAK_PASSWORD);
  bodyEncoded.append('scope', 'openid info');

  let res = await axios.post(`${KEYCLOAK_AUTH_URL}`, bodyEncoded.toString());

  setKPApiAuthData(kp_axios, res?.data);

  return res;
}
